import React, { useState,useEffect } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu, SidebarHeader, useProSidebar } from 'react-pro-sidebar';
// import '../assets/css/sidenav.css';
// import '../assets/css/custom.css';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react'
import { Service } from '../providers/service';
import { BiUserCircle } from 'react-icons/bi';
import {RiCheckboxCircleFill } from "react-icons/ri";
import { FaTwitter ,FaLinkedinIn,FaWhatsapp,FaPhoneAlt} from 'react-icons/fa';
import { AiFillDashboard,AiFillSetting,AiTwotoneBank } from 'react-icons/ai';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { IconContext } from "react-icons";
// import {} from 'react-icons/ai';
export default function SideNav() {
  const [path, setPath] = useState(window.location.pathname)
  const [view, setView] = useState()
  const { collapsed, collapseSidebar } = useProSidebar();
  const matchWidth = window.matchMedia("(max-width: 92px)").matches
  function setEndPath() {
    const path = window.location.pathname
    setPath(path)
  }

  useEffect(() => {
    // Update the document title using the browser API
    let env = localStorage.getItem('environment');
    // console.log(env);
    setView(env);
  });

  return (
    <>
      <Sidebar backgroundColor="#fafcff" defaultCollapsed={true}>
        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              // only apply styles on first level elements of the tree
              if (level === 0)
                return {
                  backgroundColor: active ? '#fbebff' : undefined,
                  color: active ? '#CC00FF' : undefined,
                  fontSize: '17px',
                  fontWeight: 'bold',
                  margin: '5% 5% 0 5%',
                  paddingTop: '30px',
                  paddingBottom: '30px',
                  borderRadius: '10px',
                  '&:hover': {
                    backgroundColor: 'dd5cff',
                    color: 'black'
                  },
                };
            },
          }}>
          <MenuItem onClick={() => { collapseSidebar() }}>{collapsed ? <Icon name='sidebar' style={{ fontSize: '25px', color: '#CC00FF' }} /> : <Icon name='close' style={{ fontSize: '25px', color: '#CC00FF' }} />}</MenuItem>
          <Link to={'/dashboard'}><MenuItem active={path == '/dashboard'} title="Dashboard" icon={<IconContext.Provider value={{ color: '#CC00FF' }}>
              <div ><AiFillDashboard size={28} /></div></IconContext.Provider>} routerLink={<Link to="/dashboard" />} onClick={setEndPath}><div style={{color:"#CC00FF"}}>Dashboard</div></MenuItem></Link>
          <Link to={'/users'}><MenuItem active={path == '/users'} title="User Information" icon={<IconContext.Provider value={{ color: '#CC00FF' }}>
              <div><BiUserCircle size={28} /></div></IconContext.Provider>} routerLink={<Link style={{color:"#CC00FF"}} to="/users" />} onClick={setEndPath}><div style={{color:"#CC00FF"}}>User Information</div> </MenuItem></Link>
          <Link to={'/transactions'}><MenuItem active={path == '/transactions'} title="Transaction" icon={<IconContext.Provider value={{ color: '#CC00FF' }}>
              <div><FaFileInvoiceDollar size={28} /></div></IconContext.Provider>} routerLink={<Link to="/transactions" />} onClick={setEndPath}><div style={{color:"#CC00FF"}}>Transactions</div></MenuItem></Link>
          {view == "adm" ? <MenuItem active={path == '/configure'} icon={<AiFillSetting style={{ fontSize: '28px' }} />} routerLink={<Link to="/configure" />} onClick={setEndPath}>Configure</MenuItem> : <></> }
          
          {view == "adm" ? <MenuItem active={path == '/bank'} icon={<AiTwotoneBank style={{ fontSize: '28px' }} />} routerLink={<Link to="/bank" />} onClick={setEndPath}>Banking</MenuItem> : <></>}
        </Menu>
      </Sidebar>
    </>
  )
}