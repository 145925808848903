import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes as Routepage, Navigate, Outlet } from "react-router-dom";
import Dashboard from '../components/dashboard';
import Transactions from '../components/transactions';
import Authorization from '../components/authorization';
import Configure from '../components/Configure';
import SideNav from '../containers/sidenav'
import TopNav from '../containers/topnav';
import Users from '../components/users';
import Bank from '../components/Bank';
import '../assets/css/custom.css';
import '../assets/css/media.css';
import { Toaster } from 'react-hot-toast';

const Error = props => {
  return (
    <div>
      <div className='page_not_found'>
        <i className="fa fa-ban" aria-hidden="true" style={{ fontSize: '100px' }}></i><br /><br />
        <h2 style={{ textTransform: 'capitalize' }}> 404 Page not found </h2>
      </div>
    </div>
  );
};

// const Error = (props) => {
//   return (
//     <div
//       style={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         flex: 1,
//       }}
//     >
//       <h2 style={{ textTransform: 'capitalize' }}> 404 Page not found </h2>
//     </div>
//   );
// };


const ProtectedRoute = () => {
  let token = localStorage.getItem("x-access-token")
  return (
    token ? <Outlet /> : <Navigate to='/' />
  )
}

class Routes extends Component {
  constructor() {
    super();
    this.state = {
      token: localStorage.getItem("x-access-token"),
      view: "",
      mobileWidth: window.matchMedia("(max-width: 600px)").matches
    };
  }
  componentDidMount = () => {
    this.setState({ view: localStorage.getItem('environment') });
  }
  render() {
    return (<>
      {this.state.token && <TopNav />}
      <Toaster
        position="top-right"
        reverseOrder={false} />
      <div style={{ display: "flex", height: this.state.mobileWidth ? "auto" : "auto", minHeight: "90vh" }}>
        <Router>
          {this.state.token && <SideNav />}
          <div style={{ width: '100%', backgroundColor: '#f5f7fa', overflowX: 'auto' }}>
            <Routepage>
              <Route exact path='/' element={<Authorization />} />
              <Route element={<ProtectedRoute />}>
                <Route exact path='/dashboard' element={<Dashboard />} />
                <Route exact path='/users' element={<Users />} />
                <Route exact path='/transactions' element={<Transactions />} />
                {this.state.view == "adm" ? <Route exact path='/configure' element={<Configure />} /> : <></>}
                {/* <Route exact path='/configure' element={<Configure />} /> */}
                {this.state.view == "adm" ? <Route exact path='/bank' element={<Bank />} /> : <></>}
                <Route exact path='/*' element={<Error />} />

              </Route>
            </Routepage>
          </div>
        </Router>
      </div>
    </>
    );
  }
}

export default Routes;