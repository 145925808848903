import React, { Component } from 'react'
import { Spinner, Row, Col, Table, Input, InputGroup, InputGroupText } from "reactstrap";
import { Button, Icon } from 'semantic-ui-react';
import { IoShareOutline } from 'react-icons/io5';
import { CSVLink } from "react-csv";
import DataTable from 'react-data-table-component';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
const dummydata = [
  {
    utr : "123hs4d",
    bene_acc_no :"alpyneeee2",
    req_type :"2022-11-24T14:09:06.074Z",
    req_dt_time : "1000",
    txn_amount: "10",
    pmode : "0",
    sndr_acnt: "0",
    sndr_nm : "1234567980abcdefgh",
    sndr_ifsc : "1234567980abcdefgh",
    txid : "62y772766387h98883hhhhskkkkhhcbbbbcbbbbbbbbbbjs",
    err_codes:"0",
    corp_code:"1",


},
{
  utr : "123hs4d",
  bene_acc_no :"alpyneeee2",
  req_type :"2022-11-24T14:09:06.074Z",
  req_dt_time : "1000",
  txn_amount: "10",
  pmode : "0",
  sndr_acnt: "0",
  sndr_nm : "1234567980abcdefgh",
  sndr_ifsc : "1234567980abcdefgh",
  txid : "62y772766387h98883hhhhskkkkhhcbbbbcbbbbbbbbbbjs",
  err_codes:"0",
  corp_code:"1",


},
{
  utr : "123hs4d",
  bene_acc_no :"alpyneeee2",
  req_type :"2022-11-24T14:09:06.074Z",
  req_dt_time : "1000",
  txn_amount: "10",
  pmode : "0",
  sndr_acnt: "0",
  sndr_nm : "1234567980abcdefgh",
  sndr_ifsc : "1234567980abcdefgh",
  txid : "62y772766387h98883hhhhskkkkhhcbbbbcbbbbbbbbbbjs",
  err_codes:"0",
  corp_code:"1",


},
{
  utr : "123hs4d",
  bene_acc_no :"alpyneeee2",
  req_type :"2022-11-24T14:09:06.074Z",
  req_dt_time : "1000",
  txn_amount: "10",
  pmode : "0",
  sndr_acnt: "0",
  sndr_nm : "1234567980abcdefgh",
  sndr_ifsc : "1234567980abcdefgh",
  txid : "62y772766387h98883hhhhskkkkhhcbbbbcbbbbbbbbbbjs",
  err_codes:"0",
  corp_code:"1",


},

]
export default class Bank extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      tab1 : true,
      tab2 : false,
      side : "0",
      loaded: false,
      bankData : [],
      export_data:"",
      search:"",
    }
  }
  async componentDidMount (){
    this.setState({ loaded: true  });
    // let side =  localStorage.getItem("side") ;
    // if (side == "1") {
    //     this.setState({ tab1 : false , tab2 : true });
    // } 
    
  }
  handleTab1 = () =>{
    localStorage.setItem("side", "0");
    this.setState({ tab1: true, tab2:false });
  }
  handleTab2 = () =>{
    localStorage.setItem("side", "1");
    this.setState({ tab2: true ,tab1:false});
  }

  log_View = <>
    <div className="logs">Alpyne log March_3_2023</div>
    <div className="logs">Alpyne log March_2_2023</div>
    <div className="logs">Alpyne log March_1_2023</div>
    <div className="logs">Alpyne log Feb_3_2023</div>
    <div className="logs">Alpyne log Feb_3_2023</div>
    <div className="logs">Alpyne log Feb_3_2023</div>
    <div className="logs">Alpyne log Feb_3_2023</div>
    <div className="logs">Alpyne log Feb_3_2023</div>
    <div className="logs">Alpyne log Feb_3_2023</div>
    <div className="logs">Alpyne log Feb_3_2023</div>
  </>

  render() {
    const { loaded, bankData, search } = this.state;
    console.log(dummydata)
    const filteredData = loaded && dummydata.filter(item => item.utr && item.utr.includes(search.toLowerCase()))
    console.log(filteredData)
    const bankColumns = [
      {
        name: 'UTR',
        selector: 'utr',
        sortable: true,
        center: true,
        width: '8%'
      },
      {
        name: 'Bene_acc_no',
        selector:"bene_acc_no",
        sortable: true,
        center: true,
        width: '9%'
      },
      {
        name: 'Req_type',
        selector: 'req_type',
        sortable: true,
        center: true,
        width: '12%'
      },
      {
        name: 'Req_dt_time',
        selector:'req_dt_time',
        sortable: true,
        center: true,
        width: '11%'
      },
      {
        name: 'Txn_amount',
        selector:'txn_amount',
        // selection:'currency_bought',
        sortable: true,
        center: true,
        width: '10%'
      },
      {
        name: 'Pmode',
        selector:'pmode',
        sortable: true,
        center: true,
        width: '8%'
      },
      {
        name: 'Sndr_acnt',
        selector:'sndr_acnt',
        sortable: true,
        center: true,
        width: '8%',
       
      },
      {
        name: 'Sndr_nm',
        selector: 'sndr_nm',
        sortable: true,
        center: true,
        width: '10%'
      },
      {
        name: 'Sndr_ifsc',
        selector:"sndr_ifsc",
        sortable: true,
        center: true,
        width: '12%'
      },
      {
        name: 'txid',
        selector: 'txid',
        sortable: true,
        center: true,
        width: '10%'
      },
      {
        name: 'Err_code',
        selector: 'err_codes',
        sortable: true,
        center: true,
        width: '10%'
      },
      {
        name: 'Corp_code',
        selector: 'corp_code',
        sortable: true,
        center: true,
        width: '10%'
      },
    ]

    const customStyles = {
      headCells: {
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: 'black',
        }
      },
      pagination: {
        style: {
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '15px'
        },
        rows: {
          style: {
              textAlign : 'left', // override the row height
          },
      },
        
      },
    };
 
    return (
      <div>
        <div className='m-5' >
        <div className=''>
        <Row>
          <Col sm="12" md="8" lg="9" xl="9" > 
                  <div className='tab'>
                    <div className='tabLink1' style={{ backgroundColor : this.state.tab1 ? "#CC00FF" : "#E3E3E3" ,color: this.state.tab1 ?"white" : "black" }} onClick={this.handleTab1}>
                        Banking Request
                    </div>
                    <div className='tabLink1' style={{ backgroundColor : this.state.tab2 ? "#CC00FF" : "#E3E3E3",color: this.state.tab2 ?"white" : "black" }} onClick={this.handleTab2}>
                        Logs
                    </div>
                </div>
                </Col>
          <Col sm="12" md="4" lg="3" xl="3" style={{textAlign:"end"}} > <CSVLink data={this.state.export_data} filename={"transactions.csv"}>
          {this.state.tab2 ? <></> : <Button className='export_botton'>Export <IoShareOutline /></Button>}
          </CSVLink></Col>
        </Row>
               
                <div>
                {this.state.tab1 ? 
                
                <table_div>
                  {loaded ?
                    <DataTable
                      columns={bankColumns}
                      data={filteredData}
                      pagination
                      paginationPerPage={50}
                      paginationRowsPerPageOptions={[50, 100, 150, 200]}
                      highlightOnHover
                      customStyles={customStyles}
                      paginationComponentOptions={{ rowsPerPageText: 'Rows per page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: true, selectAllRowsItemText: 'All' }}
                      className='rtd mt-4'
                    />
                     :
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='pt-5'><Spinner /></div>}
                </table_div>
                :  this.log_View }
                </div>

            </div>
        </div>
      </div>
    )
  }
}
