import axios from "axios";
// import { baseApiUrl } from '../constants/defaultValues'
import { baseApiUrl } from "../constant/defaultValues";


const createInstance = function () {
    const axiosInstance = axios.create({
        baseURL: baseApiUrl,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("x-access-token"),
        },
    });
    return axiosInstance;
}

export const Service = {

    auth: function (payload) {
        return new Promise(function (resolve, reject) {
            const axiosInstance = createInstance();
            axiosInstance
                .post("/api/v1/panel/auth", payload)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    getUser: function () {
        return new Promise(function (resolve, reject) {
            const axiosInstance = createInstance();
            axiosInstance
                .get("/api/v1/panel/users")
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },

    getVpas: function () {
        return new Promise(function (resolve, reject) {
            const axiosInstance = createInstance();
            axiosInstance
                .get("/api/v1/panel/vpa")
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    updateUPIStatus: function (id,payload) {
        return new Promise(function (resolve, reject) {
            const axiosInstance = createInstance();
            axiosInstance
                .put("/api/v1/panel/vpa/"+id, payload)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },

    getTrans: function () {
        return new Promise(function (resolve, reject) {
            const axiosInstance = createInstance();
            axiosInstance
                .get("/api/v1/panel/txs")
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    getDashboard: function (start_time,time_delta) {
        return new Promise(function (resolve, reject) {
            const axiosInstance = createInstance();
            axiosInstance
                .get(`/api/v1/panel/dashboard?start_time=${start_time}&time_delta=${time_delta}`)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },

    Logout: function () {
        localStorage.clear()
        window.location.reload()
    }

}