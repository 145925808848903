import React, { Component, useState } from "react";
import { GoMail } from 'react-icons/go';
import { BsPersonCircle } from 'react-icons/bs';
import { GrNotification } from 'react-icons/gr';
import alpyneLogo from "../assets/images/logo.png"
import { Container, Navbar, Nav, NavbarBrand, NavItem, NavLink, Dropdown as Dropdowns, Collapse, NavbarToggler, DropdownToggle, DropdownMenu, DropdownItem, Button, Modal, ModalBody, ModalHeader, NavbarText } from 'reactstrap'
import { Service } from "../providers/service";

class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdown: false,
      language: localStorage.getItem('wholelanguage'),
      sidebar: false,
      logout: true,
      getstartedmodal: false,
      fade: false,
      verifymodal: false,
      otp: "",
      pathname: window.location.pathname,
      scrolled: false
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    return (
      <div>
        <Navbar expand="md" className="top_nav_main py-3">
          <NavbarBrand href="/dashboard"><img alt="AlpyneLogo" src={alpyneLogo} style={{ width: "100%", height: "45px" }} /></NavbarBrand>
          <NavbarToggler onClick={this.toggle} className='top_navbar_toggler' />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className='ms-auto' navbar>
              <NavItem>
                <NavLink>
                  <span className="nav-item-email">

                   <GoMail style={{ paddingRight: "7px", width: "25px", height: "30px" }} /> <span style={{fontSize:16,paddingTop:10 }}>Support@alpyne.tech</span>
                    {/* <span className="nav-item-email-text">{localStorage.getItem("panel_email")}</span> */}
                    {/* <GrNotification style={{ paddingLeft: "25px", width: "45px", height: "45px" }} /> */}
                  </span>
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink>
                  <span className="nav-item-username">
                    <BsPersonCircle style={{ paddingRight: "12px", width: "40px", height: "40px" }} />
                    <span className="nav-item-username-text">
                      Finn warhol
                    </span>
                  </span>
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink onClick={Service.Logout}>
                  <Button className="nav-item-logout">Logout</Button>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    )
  }
}
export default TopNav;
