import React, { Component } from 'react'
import { Button} from 'reactstrap'
import ReactApexChart from "react-apexcharts";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { Service } from "../providers/service";
import { Spinner, Row, Col, Table, Input, InputGroup, InputGroupText } from "reactstrap"
import DataTable from 'react-data-table-component';
import Switch from "react-switch";
import { Modal , ModalBody} from 'reactstrap';
import {ImCross} from  'react-icons/im';
import alpyneLogo from "../assets/images/HDLogo.png";
import copy from "../assets/images/copy.png";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CSVLink } from "react-csv";  
import {FcPlus} from 'react-icons/fc';
import {RiDeleteBin6Line} from 'react-icons/ri';
import {IoEyeSharp} from 'react-icons/io5';

const dummydata = [
    
    {
    serial_no : "123hs4d",
    app_name :"alpyne",
    api_key : "asmd4a414da5dass",
    api_secret: "dummy@gmail.com",
    date_generated : "9862121",
    date_last_used : "4587892",
    status:false,
    change_status:false,
  
  },
  {
    serial_no : "56hs4d",
    app_name :"alpyne",
    api_key : "asmd4a414da5dass",
    api_secret: "dummy@gmail.com",
    date_generated : "9862121",
    date_last_used : "4587892",
    status:false,
    change_status:false,
  
  },
  {
    serial_no : "983hs4d",
    app_name :"alpyne",
    api_key : "asmd4a414da5dass",
    api_secret: "dummy@.com",
    date_generated : "9862121",
    date_last_used : "4587892",
    status:false,
    change_status:false,
  
  },
  {
    serial_no : "573hs4d",
    app_name :"alpyne",
    api_key : "amd4a414da5dass",
    api_secret: "du@gmail.com",
    date_generated : "9862121",
    date_last_used : "4587892",
    status:false,
    change_status:false,
  
  },
  {
    serial_no : "643hs4d",
    app_name :"alpyne",
    api_key : "asmd4ass",
    api_secret: "dummy@gmail.com",
    date_generated : "9862121",
    date_last_used : "4587892",
    status:false,
    change_status:false,
  
  },
  
  
  ]

  const dummydata2 = [
    
    {
    serial_no : "123hs4d",
    key : "asmd4a414da5dass",
    value: "dummy@gmail.com",
    edit :"+ delete",
    
  
  },
  {
    serial_no : "56hs4d",
    key : "asmd4a414da5dass",
    value: "dummy@gmail.com",
    edit :"+ delete",
    
  
  },
  {
    serial_no : "983hs4d",
    key : "asmd4a414da5dass",
    value: "dummy@gmail.com",
    edit :"+ delete",
    
  
  },
  {
    serial_no : "573hs4d",
    key : "asmd4a414da5dass",
    value: "dummy@gmail.com",
    edit :"+ delete",
    
  
  },
  {
    serial_no : "643hs4d",
    key : "asmd4a414da5dass",
    value: "dummy@gmail.com",
    edit :"+ delete",
    
  
  },
  
  
  ]
  

export default class Configure extends Component {
    constructor() {
        super();
        this.myRef = React.createRef(null);
        this.state = {
            time_delta: 24,
            time_delta_text: "24 hours",
            dashdata: null,
            loaded: false,
            Successful_Unique_Users :"fetching...",
            Total_Successful_Transaction:"fetching...",
            Successful_Transaction_Volume:"fetching...",
            Timeout_Unique_Users:"fetching...",
            Total_Timeout_Transaction:"fetching...",
            Timeout_Transaction_Volume:"fetching...",
            // SuccessMetricsExport : "",   
            value: '',
            modalZero:false,
            api_key:"show after api response value",
            api_secret:"also shows after api response value",
            modalZero2:false,
            Config_data: [],
            loaded: true,
            fill:false,
            checked: false,
            selectedRows:false,
            toggledClearRows:false,
            dummydata: dummydata,
            key:"",
            secret:"",
            // 
            series: [{
                name: 'series1',
                data:[8100, 10000, 6000, 11000, 4200, 10900, 10000, 10000, 6000, 10000, 4200, ]
              }],
              options: {

                title: {
                    text: 'API Key Consumption',
                    style: {
                        fontWeight: 600,
                        fontSize:18,
                    }, 
                  },
                colors:["#CC00FF"],
                chart: {
                    toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                          download: false,
                          selection: false,
                          zoom: false,
                          zoomin: true,
                          zoomout: true,
                          reset: true | '<img src="/static/icons/reset.png" width="20">',
                          pan: false,
                          
                        },
                      },
                    events: {
                        beforeMount : (chart) =>{
                            chart.windowResizeHandler()
                        },
                      },
                      
                  height: 350,
                  width: '100%',
                  type: 'area'
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  curve: 'smooth'
                },
                xaxis: {
                    // type: 'time',
                    
                    categories: ["01/02/2023","02/02/2023","03/02/2023","04/02/2023","05/02/2023","06/01/2023","07/02/2023","08/02/2023","09/02/2023","10/02/2023","11/02/2023"]
                  },

                  yaxis: {
                    title: {
                      text: "API Key Consumption",
                      style: {
                        color: "#595959",
                        fontSize: '16px',
                        fontWeight: 400,
                        marginTop:40,
                        cssClass: 'apexcharts-xaxis-title',
                    }, 
                    
                    },
                    min: 0,
                },
                tooltip: {
                    x: {
                        format: 'dd MMM yyyy'
                      }
                  
                },
              },     
        };
        };

        
        async componentDidMount() {          
            // * hit configure Api consumption api *
            // await Service.(START_TIME,TIME_DELTA) 
            //     .then(res=>{
            //         console.log(res); 
            //         console.log(res.total_orders);
            //         //{"total_orders":1230,"timed_out_orders":1000,"success_orders":230,"total_inr":155430,"timed_out_inr":1223230,"success_inr":321230,"highest_inr_success":25000,"highest_inr_timeout":100000,"average_inr":12000,"average_inr_timeout":23450,"average_inr_success":1000,"total_users":230,"users_orders_placed":1230,"users_success_orders_placed":500,"new_users":24}
            //         this.setState({ Successful_Unique_Users: res.users_success_orders_placed, Successful_Transaction_Volume : res.          success_inr ,Total_Successful_Transaction : res.success_orders ,Timeout_Unique_Users : res.users_orders_placed ,Total_Timeout_Transaction: res.timed_out_orders ,Timeout_Transaction_Volume : res.timed_out_inr });
            //         // let SuccessMetricsExport = {  }
            //     })
            //     .catch(err => console.log(err))

            //* hit Api keys config_Table *
            // await Service.()     
            // .then(response => {
            //   console.log("response", response);
            //   this.setState({  Config_data: response, loaded: true })
            // })
            // .catch(error => {
            //   console.log(error);
            //   if(error.response.status == 401){
            //     Service.Logout()
            //   }
            // })
    }

    export = () =>{
        // const { image, takeScreenshot, isLoading, clear } = takeScreenshot({ref:myRef});
        console.log("export button pressed");
        const doc = new jsPDF();
        const take = document.getElementById("dashboard-area")
        html2canvas(take,{logging: true, letterRendering:1, useCORS:true}).then(canvas => {
            const imageWidth = 208;
            const imageHeight = canvas.height * imageWidth / canvas.width;
            const  imageData = canvas.toDataURL('img/png');
            doc.addImage(imageData,'PNG',0,0,imageWidth,imageHeight);
            doc.save("a4.pdf"); 
        })
       
    }
    handleShowDialog = (index) => {
        if (this.state.modalZero != true) {
        
         this.setState({ modalZero: !this.state.modalZero  });
        }else {
            this.setState({ modalZero: !this.state.modalZero  });
        } 
       };
       onChange = (e) => {
           let fillTrue = e.target.value.length;
           if(fillTrue >= 4){
               this.setState({ value: e.target.value ,fill : true });
               
           }
      };
      handleContinue = () =>{
        this.handleShowDialog();
        // this.setState({modalZero2:true})
        this.handleShowDialog2();
      }
      handleShowDialog2 = (key,secret) => {
       this.setState({key,secret,modalZero2:true})
       };

       handleChange(id) {
        console.log("Id coming",id)
        let data = this.state.dummydata.map(item =>{
          return item.serial_no == id ?  {...item, status : !item.status} : {...item}
        })
        this.setState({dummydata:data },()=>{console.log("DD",this.state.dummydata)});
        // if status is true then do change cheked state
      }

      handleChange2 = ({ selectedRows }) => {
        // setSelectedRows(selectedRows)
        this.setState({ selectedRows : selectedRows });
      };
      
      // // Toggle the state so React Data Table changes to clearSelectedRows are triggered
      // handleClearRows = () => {
        
      //   this.setState({ toggledClearRows:!this.state.toggledClearRows  });
      // }
    
      
    // formatDate=(timestamp)=>{
    //     let d = new Date(timestamp*1000);
    //     return `${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`
    //   }

    handleUrlInput=(e)=>{
      console.log(e.target.value);
    }
        

    render() {
        const { loaded, userdata, search } = this.state;
        const filteredData = loaded && this.state.dummydata.filter(item => item._id && item._id.includes(search.toLowerCase()));
    
        const dashcolumns = [
          {
            name: 'Serial no',
            selector: 'serial_no',
            sortable: true,
            center: true
          },
          {
            name: 'App Name',
            selector: 'app_name',
            sortable: true,
            center: true
          },
          {
            name: 'API Key',
            cell : (row) =>(
              <div><span>{row.api_key.slice(0,5)}</span><span>-xxxx</span> <IoEyeSharp style={{cursor:"pointer"}}  size={25} onClick={()=>this.handleShowDialog2(row.api_key,row.api_secret)} /> </div>
            ),
            // selector: 'api_key',
            sortable: true,
            center: true
          },
          {
            name: 'API Secret',
            cell : (row) =>(
              <div><span>{row.api_secret.slice(0,5)}</span><span>-xxxx</span> <IoEyeSharp style={{cursor:"pointer"}} size={25} onClick={()=>this.handleShowDialog2(row.api_key,row.api_secret)} /> </div>
            ),
            sortable: true,
            center: true
          },
          {
            name: 'Date Generated',
            // cell: (filteredData) => <div style={{whiteSpace:"pre-line",textAlign:"center"}}>{this.formatDate(filteredData.createdAt)}</div>,
            selector:"date_generated",
            sortable: true,
            center: true,
            width: '15%'
          },
          {
            name: 'Date Last Used',
            // cell: (filteredData) => <div style={{whiteSpace:"pre-line",textAlign:"center"}}>{this.formatDate(filteredData.createdAt)}</div>,
            selector:"date_last_used",
            sortable: true,
            center: true,
            width: '15%'
          },
          {
            name: 'Status',
            
            cell : (row)=><div>{row.status ? "Active":"Inactive"}</div>,
            sortable: true,
            center: true,
            ignoreRowClick: true
          },
          {
            name: 'Change Status',
            cell: (row) => ( <div>
                              <Switch
                              onChange={()=>this.handleChange(row.serial_no)}
                              checked={row.status}
                              className="react-switch"
                              id="normal-switch"/>
                            </div>),
          selector:"change_status",
            sortable: true,
            center: true
          }
        ]
        
        const dashcolumns2 = [
          {
            name: 'Serial no',
            selector: 'serial_no',
            sortable: true,
            center: true
          },
          {
            name: 'Key',
            selector: 'key',
            sortable: true,
            center: true
          },
          {
            name: 'Value',
            selector: 'value',
            sortable: true,
            center: true
          },
          {
            name: 'Edit',
            cell: (row) => (<>
              <FcPlus size={25} style={{cursor:"pointer"}} onClick={() => alert("edit")} />   
              <RiDeleteBin6Line size={25} style={{marginLeft:10,cursor:"pointer"}}  onClick={()=> alert("deleted")} />
            </>),
            sortable: true,
            center: true
          },
        ]
        const customStyles = {
          headCells: {
            style: {
              fontSize: '15px',
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: 'black',
            }
          },
          pagination: {
            style: {
              borderBottomLeftRadius: '20px',
              borderBottomRightRadius: '20px'
            },
            
          },
        };
        return (
            <>
            <div id='dashboard-area' style={{padding:20,overflowX:"auto"}}>
                <div style={{padding:"10px 5px 100px 5px"}}>

           
                        <Row >
                            <Col sm="8" md="9" lg="6" xl="6">
                                <p  style={{fontSize:34,fontWeight:"bolder"}}>API-Keys</p>
                            </Col>
                            <Col sm="4" md="3" lg="6" xl="6" >
                                <div style={{display:"flex", justifyContent:"flex-end"}}>
                                {/* <div  style={{textAlign:"end"}} >
                                </div> */}
                                <div>
        
                                <Button className='export_botton generateApiKey'  onClick={this.handleShowDialog} >Generate API Key</Button>
                               
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    
                        <div className='pt-1 pb-5 p-4'>
                            
                            {loaded ?
                            <DataTable
                                columns={dashcolumns}
                                data={this.state.dummydata}
                                pagination
                                paginationPerPage={50}
                                paginationRowsPerPageOptions={[50, 100, 150, 200 ]}
                                highlightOnHover
                                customStyles={customStyles}
                                paginationComponentOptions={{ rowsPerPageText: 'Rows per page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: true, selectAllRowsItemText: 'All' }}
                                className='rtd mt-4'
                            /> :
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='pt-5'><Spinner /></div>}
                        </div>
                        
                    <Row>
                        <div className='dashboard-graph'>
                            <div className='dashboard-chart'>
                                
                                <div className='dashboard-c'>
                                <ReactApexChart options={this.state.options} series={this.state.series} type="area"  height={350}  />
                                </div>
                            </div>
                            <div className='graph-header graph-x-p'  >Time</div>
                        </div>
                        
                        </Row>   

                        <div className='pt-5'>
                        <Row >
                            <Col sm="8" md="9" lg="6" xl="6">
                                <p style={{fontSize:34,fontWeight:"bolder"}}>Webhook</p>
                            </Col>
                            <Row className='mt-3'>
                              <Col sm="6" md="12" lg="8" xl="8"  className='px-5' >
                                <span style={{fontSize:24,fontWeight:600}}>Url : </span> <input type="text"  className="py-2" style={{outline:"none",border:"1px solid 	#DCDCDC",width:"90%",height:40}} onChange={(e) => this.handleUrlInput(e)} /> 
                              </Col>
                            </Row>
                            <Row>
                            {loaded ?
                              <DataTable
                                columns={dashcolumns2}
                                data={dummydata2}
                                pagination
                                paginationPerPage={50}
                                paginationRowsPerPageOptions={[50, 100, 150, 200 ]}
                                highlightOnHover
                                customStyles={customStyles}
                                paginationComponentOptions={{ rowsPerPageText: 'Rows per page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: true, selectAllRowsItemText: 'All' }}
                                className='rtd mt-4'
                            /> :
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='pt-5'><Spinner /></div>}
                            </Row>
                        </Row>
                        
                        
                        </div>
                        
                    </div>
                
        </div>
        <Modal contentClassName="custom-modal-style"   isOpen={this.state.modalZero } >
           <div >                    
        <ModalBody className='mt-1 mb-1' > 
        <div >
                <div className='d-flex justify-content-end'  onClick={this.handleShowDialog}>
                <ImCross />
                </div>
                <div className='d-flex justify-content-center p-4 '>
                <img alt="AlpyneLogo" src={alpyneLogo} style={{ width: "50%", height: "45px" }} />  
                </div>
                <div className=' mt-5 px-4 '>
                    <div style={{fontWeight:700,fontSize:24,}}>Enter Your App Name </div>
                    <input className='mt-3 border api-Generate-Input'    placeholder='Enter only alphabets and character' type="text" size={60}   onChange={this.onChange} onKeyPress={(event) => {
                                    if ( /[^a-z]/gi.test(event.key)) {
                                    event.preventDefault();
                                    }
                                    }} />
                    <div className=' mt-5 px-4 d-flex justify-content-center p-4 ' >
                                    
                    {this.state.fill ?  <button className="button big_screen " onClick={this.handleContinue}>Continue</button>
                        :
                        <button className="button disabled_button big_screen">Continue</button>  }
                    </div>
                    <br />
                    <br />
                </div>
                <hr className='mt-5' style={{color:"gray",backgroundColor:"gray"}} />
                    <p>If you are facing any issues, please send us an email at support@alpyne.tech</p>
          </div>
        </ModalBody>
        </div> 
       
      </Modal>

      <Modal contentClassName="custom-modal-style"   isOpen={this.state.modalZero2 } >
           <div >                    
        <ModalBody className='mt-1 mb-1' > 
        <div >
                <div className='d-flex justify-content-end' onClick={()=>this.setState({modalZero2:false})}>
                <ImCross />
                </div>
                <div className='d-flex justify-content-center p-4 '>
                <img alt="AlpyneLogo" src={alpyneLogo} style={{ width: "50%", height: "45px" }} />  
                </div>
                <div className=' mt-5 px-4 '>
                    <div style={{fontWeight:700,fontSize:24,display:"flex",justifyContent:"space-between"}}>
                        <div>
                        API Key : 
                        </div>
                        <div>
                        <span style={{fontWeight:400,fontSize:20,}} >{this.state.key}
                        <CopyToClipboard text={'123456789-abcdef-547896'}> 
                            {/* if text is dynamic then just place the dynamic state name into the CopyToClipboard text area */}
                            <button className="copyBtn"><img src={copy} className="copyimg" alt='india_flag'/></button>
                            </CopyToClipboard>
                            </span>
                        </div> 
                     </div>
                    <br />
                    <br />
                    <div style={{fontWeight:700,fontSize:24,display:"flex",justifyContent:"space-between"}}>
                        <div>
                        API Secret : 
                        </div>
                        <div>
                        <span style={{fontWeight:400,fontSize:20,}} >{this.state.secret} 
                        <CopyToClipboard text={'123456789-abcdef-547896'}> 
                            {/* if text is dynamic then just place the dynamic state name into the CopyToClipboard text area */}
                            <button className="copyBtn"><img src={copy} className="copyimg" alt='india_flag'/></button>
                            </CopyToClipboard>
                            </span>
                        </div> 
                     </div>
                    <div className=' mt-5 px-4 d-flex justify-content-center p-4 ' >
                                    
                     <CSVLink className="button big_screen " data={`API Key :${this.state.api_key}, API Secret : ${this.state.api_secret} `} filename={"user-information.csv"}>
                      <button className="button big_screen " onClick={this.handleVerifyEmail}>Download CSV File</button>
                     </CSVLink>
                        
                    </div>
                    <br />
                    <br />
                    
                    
                </div>
                <hr className='mt-5' style={{color:"gray",backgroundColor:"gray"}} />
                    <p>If you are facing any issues, please send us an email at support@alpyne.tech</p>
          </div>
        </ModalBody>
        </div> 
       
      </Modal>
      </>
        )
    }
}
