import React, { Component } from 'react'
import { Service } from "../providers/service";
import { Spinner, Row, Col, Table, Input, InputGroup, InputGroupText, Label } from "reactstrap"
import DataTable from 'react-data-table-component';
import { Button } from 'semantic-ui-react';
import searchlogo from "../assets/images/search.png";
import logo from "../assets/images/logo.png"
import { IoShareOutline } from 'react-icons/io5';
import { CSVLink } from "react-csv";
import { BiEdit } from 'react-icons/bi';
import { TfiNewWindow } from 'react-icons/tfi';
import { TiTick } from 'react-icons/ti';
import { RxCrossCircled } from 'react-icons/rx';
import { IoEyeSharp } from 'react-icons/io5';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ImCross } from 'react-icons/im';
import alpyneLogo from "../assets/images/HDLogo.png";
import copy from "../assets/images/copy.png";
import cross from "../assets/images/red-cross.png";
import tick from "../assets/images/tick.png";
import viewdetails from "../assets/images/new-window.png";
import { IoMdArrowDropdown } from 'react-icons/io';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { toast } from 'react-hot-toast';
// const dummydata = [

//This is dummy
//   {
//   _id : "123hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892",
//   createdAt:"2022-11-30T09:55:25.015Z",
//   updatedAt :"2022-11-30T09:55:25.062Z",
//   kyc_status:0,

// },
// {
//   _id : "56hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// {
//   _id : "983hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// {
//   _id : "573hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// {
//   _id : "643hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// {
//   _id : "123hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// {
//   _id : "56hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// {
//   _id : "983hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// {
//   _id : "573hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// {
//   _id : "643hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// {
//   _id : "123hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// {
//   _id : "56hs4d",
//   name :"john",
//   vendor_user_id : "bbbbb",
//   email: "working.com",
//   contact : "457895212",
//   vac : "hdfc1244"

// },
// {
//   _id : "983hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// {
//   _id : "573hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// {
//   _id : "643hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// {
//   _id : "123hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// {
//   _id : "56hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// {
//   _id : "983hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// {
//   _id : "573hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// {
//   _id : "643hs4d",
//   name :"alpyne",
//   vendor_user_id : "asmd4a414da5dass",
//   email: "dummy@gmail.com",
//   contact : "9894562121",
//   vac : "45621255487892"

// },
// ]

export default class Users extends Component {
  constructor() {
    super();
    this.state = {
      value: '',
      userdata: [],
      users_upi_data: {},
      loaded: false,
      search: '',
      export_data: "",
      modalZero2: false,
      openEditModal: false,
      name: "",
      _id: "",
      vendor_user_id: "",
      email: "",
      contact: "",
      vac: "",
      kyc_status: "",
      clientDropDown: "Delta Exchange",
      view: "",
      selectedIndex: '',
      selectedUPIList: [],
      vendorsIdsList: [],
      selectedVendorId: '',
      enable_actions: false,
      statusList: [{ id: 0, name: 'Added', color: 'primary-color' }, { id: 1, name: 'Verified', color: 'success-color' },
      { id: 2, name: 'Invalid', color: 'warning-color' }, { id: 3, name: 'Blocked', color: 'danger-color' },
      { id: 4, name: 'Name Mismatch', color: 'danger-color' }
      ]
    };
  }
  async componentDidMount() {
    await Service.getUser()
      .then(response => {
        //console.log("user response", response);
        let Export_data = response.map(item => {
          if (item.vendor_id == '637e0d4bed4bc739398ef17d') {
            return {
              kyc_status: item.kyc_status, user_id: item._id, vendor_user_id: item.vendor_user_id, vendor_id: item.vendor_id,
              createdAt: item.createdAt, updatedAt: item.updatedAt, vac: item.vac
            }
          } else {
            return {
              kyc_status: item.kyc_status, user_id: item._id, vendor_user_id: item.vendor_user_id, vendor_id: item.vendor_id,
              createdAt: item.createdAt, updatedAt: item.updatedAt, vac: item.vac, __v: item.__v, name: item.name
            }
          }

        })
        let role = localStorage.getItem('panel_role')
        let vendorsIds = response.map(x => x.vendor_id)
        this.setState({
          userdata: response, export_data: Export_data, loaded: true,
          view: localStorage.getItem('environment'),
          // enable_actions: role && Number(role) !== 2 && '' ? true : false
          enable_actions: role && (Number(role) === 0 || Number(role) === 1) ? true : false,
          vendorsIdsList: [...new Set(vendorsIds)],
        })


      })
      .catch(error => {
        console.log(error);
        if (error.response.status == 401) {
          Service.Logout()
        }
      })
    await this.fetchUPI()
  }

  //my edition

  fetchUPI = async () => {
    await Service.getVpas().then((response) => {
      //console.log('VPA RES', response);
      if (response) {
        this.setState({ users_upi_data: response })
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  onEditUser = (row) => {
    let selected = this.state.users_upi_data[row._id]
    let UPILIST = selected || []
    this.setState({
      ...row, openEditModal: true, selectedUPIList: UPILIST.map(x => {
        x['old_status'] = x.status;
        x['disable_actions'] = true;
        return x
      })
    })
  };

  handleupdateStatus = (ele, status) => {
    let list = this.state.selectedUPIList;
    let index = list.findIndex(x => x._id === ele._id)
    if (index !== -1) {
      list[index].status = status;
      list[index].disable_actions = status === list[index].old_status ? true : false
    }
    this.setState({ selectedUPIList: list })
  }

  clearUpdateStatus = (ele) => {
    let list = this.state.selectedUPIList;
    let index = list.findIndex(x => x._id === ele._id)
    if (index !== -1) {
      list[index].status = list[index].old_status;
      list[index].disable_actions = true
    }
    this.setState({ selectedUPIList: list })
  }

  onCloseClick = () => {
    let list = this.state.selectedUPIList;
    for (let item of list) {
      item.status = item.old_status;
      item.disable_actions = true
    }

    this.setState({ openEditModal: false, selectedUPIList: list })
  }
  handleExport = async () => {
    console.log('export');
  }

  DeltaDropDown = () => {
    this.setState({ clientDropDown: "Delta Exchange" })
  }

  polysportsDropDown = () => {
    this.setState({ clientDropDown: "Polysposrts" })
  }

  plenaDropDown = () => {
    this.setState({ clientDropDown: "Plena" });
  }


  syptoDropDown = () => {
    this.setState({ clientDropDown: "Sypto" });
  }


  handleInput = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }
  handleVendorChange = (e) => {
    const { value } = e.target
    this.setState({ selectedVendorId: value })

  }
  handleShowDialog2 = (name, _id, vendor_user_id, email, contact, vac, kyc_status) => {
    this.setState({ name, _id, vendor_user_id, email, contact, vac, kyc_status, modalZero2: true })
  };

  getStatusData = (status) => {
    let statusItem = this.state.statusList.find(x => x.id === status)
    if (statusItem) return statusItem

    return { name: status, color: 'danger' }
  }
  updateUPIStatus = async (row) => {
    await Service.updateUPIStatus(row._id, { status: row.status }).then((response) => {
      if (response) {
        toast.success('STATUS UPDATED SUCCESSFULLY')
        let list = this.state.selectedUPIList;
        let index = list.findIndex(x => x._id === row._id)
        if (index !== -1) {
          list[index].disable_actions = true
          list[index].old_status = row.status
        }
        this.setState({ selectedUPIList: list })
      }
    }).catch((error) => {
      console.log(error);
      toast.error(error.message)
      this.clearUpdateStatus(row)
    })
  }

  render() {
    const { loaded, userdata, search, selectedVendorId } = this.state;
    let filteredData = loaded && userdata.filter(item => item._id && item._id.includes(search.toLowerCase()) || item.name && item.name.includes(search.toLowerCase()) || item.vendor_user_id && item.vendor_user_id.includes(search.toLowerCase()) || item.email && item.email.includes(search.toLowerCase()) || item.contact && item.contact.includes(search.toLowerCase()) || item.vac && item.vac.includes(search.toLowerCase()));
    let role = localStorage.getItem('panel_role')

    if (selectedVendorId) {
      filteredData = filteredData.filter(x => x.vendor_id === selectedVendorId)
    }
    let dashcolumns = [
      {
        name: 'User ID',
        selector: '_id',
        sortable: true,
        center: true
      },

      {
        name: 'Vendor ID',
        selector: 'vendor_id',
        sortable: true,
        center: true
      }
      ,
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        center: true
      },
      {
        name: 'Vendor User ID',
        selector: 'vendor_user_id',
        sortable: true,
        center: true
      },
      {
        name: 'Email',
        selector: 'email',
        sortable: true,
        center: true
      },
      {
        name: 'Contact',
        selector: 'contact',
        sortable: true,
        center: true
      },
      {
        name: 'KYC Status',
        cell: (x) => x.kyc_status === "0" ? <div className="trans_circle trans_yellow">Processing</div> :
          x.kyc_status === "1" ? <div className="trans_circle trans_green">Success</div> :
            <div className="trans_circle trans_red">Failed</div>,
        sortable: true,
        center: true,
        ignoreRowClick: true
      },
      {
        name: 'VAC',
        selector: 'vac',
        sortable: true,
        center: true
      },
      {
        name: 'UPI Details',
        cell: (row) => (
          <div> <TfiNewWindow style={{ cursor: "pointer" }} size={25} onClick={() => this.onEditUser(row)} /> </div>
        ),
        sortable: true,
        center: true
      }
    ]

    if (role && Number(role) === 3) {
      dashcolumns.splice(1, 1)
    }
    const dashcolumnsAdmin = [
      {
        name: 'User ID',
        selector: '_id',
        sortable: true,
        center: true
      },
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        center: true
      },
      {
        name: 'Vendor User ID',
        selector: 'vendor_user_id',
        sortable: true,
        center: true
      },
      {
        name: 'Email',
        selector: 'email',
        sortable: true,
        center: true
      },
      {
        name: 'Contact',
        selector: 'contact',
        sortable: true,
        center: true
      },
      {
        name: 'KYC Status',
        cell: (x) => x.kyc_status === "0" ? <div className="trans_circle trans_yellow">Processing</div> :
          x.kyc_status === "1" ? <div className="trans_circle trans_green">Success</div> :
            <div className="trans_circle trans_red">Failed</div>,
        sortable: true,
        center: true,
        ignoreRowClick: true
      },
      {
        name: 'VAC',
        selector: 'vac',
        sortable: true,
        center: true
      },
      {
        name: 'View details',
        cell: (row) => (
          <div> <IoEyeSharp style={{ cursor: "pointer" }} size={25} onClick={() => this.handleShowDialog2(row.name, row._id, row.vendor_user_id, row.email, row.contact, row.vac, row.kyc_status)} /> </div>
        ),
        sortable: true,
        center: true
      }
    ]

    const customStyles = {
      headCells: {
        style: {
          fontSize: '15px',
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: 'black',
        }
      },
      pagination: {
        style: {
          borderBottomLeftRadius: '20px',
          borderBottomRightRadius: '20px'
        },
        // pageButtonsStyle: {
        //   borderRadius: '50%',
        //   height: '40px',
        //   width: '40px',
        //   padding: '8px',
        //   margin: 'px',
        //   cursor: 'pointer',
        // },
      },
    };


    return (
      <div className='pt-5 pb-5 p-4'>
        <Row>
          <Col sm="4" md="4" lg="8" xl="3">
            <InputGroup>
              <Input type="text" name='search' value={search} placeholder='Search(User ID,Name,Vendor User ID,Email)' onChange={this.handleInput} className='search_input' />
              <InputGroupText style={{ backgroundColor: 'white', border: 'none' }}>
                <img src={searchlogo} />
              </InputGroupText>
            </InputGroup>
          </Col>

          <Col sm="4" md="4" lg="4" xl="3">
            {
              (role && Number(role) !== 3) &&
              (<div class="two fields custom-vendor-dropdown">
                <div class="field">
                  <select value={this.state.selectedVendorId} onChange={this.handleVendorChange} className="ui fluid dropdown">
                    <option value="">Select VendorId</option>
                    {
                      this.state.vendorsIdsList.map(x => (
                        <option value={x}>{x}</option>
                      ))
                    }
                  </select>
                </div>
              </div>)
            }

          </Col>

          <Col sm="4" md="4" lg="4" xl="6" style={{ textAlign: "end" }}>
            {this.state.view == "adm" ? <div class="dropdown" style={{ minWidth: 194 }} >
              <span style={{ color: "#595959" }}>{this.state.clientDropDown} <IoMdArrowDropdown /></span>
              <div class="dropdown-content" style={{ minWidth: 190 }}>
                <p style={{ color: "#595959", textAlign: "center" }} onClick={this.DeltaDropDown} >Delta Exchange</p>           {/* //onClick={this.handleDropDown} */}

                <p style={{ color: "#595959", textAlign: "center" }} onClick={this.polysportsDropDown} >Polysports</p>
                <p style={{ color: "#595959", textAlign: "center" }} onClick={this.plenaDropDown} >Plena</p>
                <p style={{ color: "#595959", textAlign: "center" }} onClick={this.syptoDropDown}  >Sypto</p>
              </div>
            </div> : <></>}

            <CSVLink data={this.state.export_data} filename={"User_Information.csv"}><Button className='export_botton'>Export <IoShareOutline /></Button></CSVLink>
          </Col>
        </Row>
        {loaded ?
          <DataTable
            columns={this.state.view == "adm" ? dashcolumnsAdmin : dashcolumns}
            data={filteredData}
            pagination
            paginationPerPage={50}
            paginationRowsPerPageOptions={[50, 100, 150, 200]}
            highlightOnHover
            customStyles={customStyles}
            paginationComponentOptions={{ rowsPerPageText: 'Rows per page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: true, selectAllRowsItemText: 'All' }}
            className='rtd mt-4 '
          /> :
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='pt-5'><Spinner /></div>}

        {/* my edition of code */}

        <Modal isOpen={this.state.openEditModal} className='upi-modal'>
          <div >
            <ModalHeader className='edit-modal-user' toggle={() => this.onCloseClick()}>

              <div style={{ display: 'flex', justifyContent: "center" }} className="text-align-center">
                <img src={logo} alt="Alpyne Logo" />
              </div>
            </ModalHeader>
            <ModalBody className='mt-1 mb-1 custom-upi-table' >
              {
                this.state.selectedUPIList.length ?

                  <table className="table text-center  ">
                    <thead className="bg-dark text-white">
                      <tr>
                        <th>S.No</th>
                        <th>User Name</th>
                        <th>UPI ID</th>
                        <th>Status</th>
                        {
                          this.state.enable_actions ?
                            <th>Actions</th> : <></>
                        }

                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.selectedUPIList.map((x, index) =>
                          <tr className="align-middle" key={index}>
                            <td>{index + 1}</td>
                            <td className='text'>
                              <span>
                                {this.state?.name || ''}
                              </span>
                            </td>
                            <td>{x.vpa}</td>
                            <td>
                              <div className={`custom-dropdown ${!this.state.enable_actions ? "disable-dropdown" : ""}`}>
                                <Dropdown isOpen={this.state.selectedIndex === index + 1} toggle={() => this.setState({ selectedIndex: this.state.selectedIndex != index + 1 ? index + 1 : '' })} direction={'down'}>
                                  <DropdownToggle color={this.getStatusData(x.status)?.color} caret>
                                    {this.getStatusData(x.status)?.name}
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    {
                                      this.state.statusList.filter(y => y.id != x.status).map(z =>
                                        <DropdownItem onClick={() => this.state.enable_actions && this.handleupdateStatus(x, z.id)}>{z.name}</DropdownItem>
                                      )
                                    }

                                  </DropdownMenu>
                                </Dropdown>
                              </div>
                            </td>
                            {
                              this.state.enable_actions ?
                                <td>
                                  <div className={`d-flex gap-2  justify-content-center  ${x.disable_actions ? 'disable-actions' : ''}`}>
                                    <img
                                      onClick={() => this.state.enable_actions && this.updateUPIStatus(x)}
                                      className='cursor-pointer' src={tick} width="25" height="25" alt="Alpyne Logo" />{"   "}
                                    <img
                                      className='cursor-pointer'
                                      onClick={() => this.state.enable_actions && this.clearUpdateStatus(x)} src={cross} width="25" height="25" alt="Alpyne Logo" />
                                  </div>

                                </td> : <></>
                            }

                          </tr>
                        )
                      }


                    </tbody>
                  </table> : <>
                    <div className='text-center text-danger'>
                      <strong>NO UPI IDs FOUND</strong>
                    </div>
                  </>
              }
            </ModalBody>
          </div>

        </Modal>


        {/* my edition of code end */}




        <Modal isOpen={this.state.modalZero2} >
          <div >
            <ModalBody className='mt-1 mb-1' >
              <div >
                <div className='d-flex justify-content-end' onClick={() => this.setState({ modalZero2: false })}>
                  <ImCross />
                </div>
                <div className='d-flex justify-content-center p-4 '>
                  <img alt="AlpyneLogo" src={alpyneLogo} style={{ width: "50%", height: "45px" }} />
                </div>
                <div className=' mt-5 px-4 '>
                  <div style={{ fontWeight: 700, fontSize: 24, display: "flex", justifyContent: "space-between" }}>
                    <div>
                      User ID :
                    </div>
                    <div>
                      <span style={{ fontWeight: 400, fontSize: 20, }} >{this.state._id}
                        <CopyToClipboard text={'123456789-abcdef-547896'}>
                          {/* if text is dynamic then just place the dynamic state name into the CopyToClipboard text area */}
                          <button className="copyBtn"><img src={copy} className="copyimg" alt='india_flag' /></button>
                        </CopyToClipboard>
                      </span>
                    </div>
                  </div>
                  <br />
                  <div style={{ fontWeight: 700, fontSize: 24, display: "flex", justifyContent: "space-between" }}>
                    <div>
                      Name :
                    </div>
                    <div>
                      <span style={{ fontWeight: 400, fontSize: 20, }} >{this.state.name}
                        <CopyToClipboard text={'123456789-abcdef-547896'}>
                          {/* if text is dynamic then just place the dynamic state name into the CopyToClipboard text area */}
                          <button className="copyBtn"><img src={copy} className="copyimg" alt='india_flag' /></button>
                        </CopyToClipboard>
                      </span>
                    </div>
                  </div>
                  <br />
                  <div style={{ fontWeight: 700, fontSize: 24, display: "flex", justifyContent: "space-between" }}>
                    <div>
                      Vendor User ID :
                    </div>
                    <div>
                      <span style={{ fontWeight: 400, fontSize: 20, }} >{this.state.vendor_user_id}
                        <CopyToClipboard text={'123456789-abcdef-547896'}>
                          {/* if text is dynamic then just place the dynamic state name into the CopyToClipboard text area */}
                          <button className="copyBtn"><img src={copy} className="copyimg" alt='india_flag' /></button>
                        </CopyToClipboard>
                      </span>
                    </div>
                  </div>
                  <br />
                  <div style={{ fontWeight: 700, fontSize: 24, display: "flex", justifyContent: "space-between" }}>
                    <div>
                      Email :
                    </div>
                    <div>
                      <span style={{ fontWeight: 400, fontSize: 20, }} >{this.state.email}
                        <CopyToClipboard text={'123456789-abcdef-547896'}>
                          {/* if text is dynamic then just place the dynamic state name into the CopyToClipboard text area */}
                          <button className="copyBtn"><img src={copy} className="copyimg" alt='india_flag' /></button>
                        </CopyToClipboard>
                      </span>
                    </div>
                  </div>
                  <br />
                  <div style={{ fontWeight: 700, fontSize: 24, display: "flex", justifyContent: "space-between" }}>
                    <div>
                      contact :
                    </div>
                    <div>
                      <span style={{ fontWeight: 400, fontSize: 20, }} >{this.state.contact}
                        <CopyToClipboard text={'123456789-abcdef-547896'}>
                          {/* if text is dynamic then just place the dynamic state name into the CopyToClipboard text area */}
                          <button className="copyBtn"><img src={copy} className="copyimg" alt='india_flag' /></button>
                        </CopyToClipboard>
                      </span>
                    </div>
                  </div>
                  <br />
                  <div style={{ fontWeight: 700, fontSize: 24, display: "flex", justifyContent: "space-between" }}>
                    <div>
                      KYC :
                    </div>
                    <div>
                      <span style={{ fontWeight: 400, fontSize: 20, }} >{this.state.kyc_status}
                        <CopyToClipboard text={'123456789-abcdef-547896'}>
                          {/* if text is dynamic then just place the dynamic state name into the CopyToClipboard text area */}
                          <button className="copyBtn"><img src={copy} className="copyimg" alt='india_flag' /></button>
                        </CopyToClipboard>
                      </span>
                    </div>
                  </div>
                  <br />
                  <div style={{ fontWeight: 700, fontSize: 24, display: "flex", justifyContent: "space-between" }}>
                    <div>
                      Vac :
                    </div>
                    <div>
                      <span style={{ fontWeight: 400, fontSize: 20, }} >{this.state.vac}
                        <CopyToClipboard text={'123456789-abcdef-547896'}>
                          {/* if text is dynamic then just place the dynamic state name into the CopyToClipboard text area */}
                          <button className="copyBtn"><img src={copy} className="copyimg" alt='india_flag' /></button>
                        </CopyToClipboard>
                      </span>
                    </div>
                  </div>
                </div>
                <hr className='mt-5' style={{ color: "gray", backgroundColor: "gray" }} />
                <p>If you are facing any issues, please send us an email at support@alpyne.tech</p>
              </div>
            </ModalBody>
          </div>

        </Modal>
      </div>
    );
  }
}
