import React, { Component } from 'react';
// import '../assets/css/custom.css';
import { Button, Segment, Form, Grid, Image } from 'semantic-ui-react'
import { Navigate } from 'react-router-dom'
import { Service } from '../providers/service';
import validator from 'validator';
import logo from "../assets/images/logo.png"
const crypto = require('crypto');

export default class Autherization extends Component {
    state = {
        isShown: false,
        passwordLength: 0,
        navigate: false,
        Text: 'Show',
        emailError: false,
        email: "",
        password: "",
        
    }

    validateEmail = (e) => {
        this.setState({ email: e.target.value })
    }


    PasswordHandler = (e) => {
        let password = e.target.value;
        let passwordLength = password.length;
        this.setState({ password: password, passwordLength: passwordLength })
        if (validator.isEmail(this.state.email)) {
            this.setState({ emailError: true });
        } else {
            this.setState({ emailError: false });
        }
    }

    togglePassword = (e) => {
        e.preventDefault();
        this.setState({ isShown: !this.state.isShown, Text: "Hide" });
        if (this.state.Text == "Hide") {
            this.setState({ isShown: !this.state.isShown, Text: "Show" });
        }
    }


    handleVerifyEmail = async () => {

        console.log("login", this.state.email, this.state.password);
        let hmac = crypto
            .createHmac("sha512", "")
            .update(this.state.password)
            .digest("hex")
        console.log(hmac);
        const payload = { email: this.state.email, password: hmac };
        console.log(payload);

        await Service.auth(payload)
            .then(res => {
                console.log("Auth",res);
                // if (res.role == "admin") {
                //     localStorage.setItem("environment","adm")
                // } 
                localStorage.setItem("environment","merch")
                
                localStorage.setItem("x-access-token", res.token)
                localStorage.setItem("panel_email", this.state.email)
                localStorage.setItem("panel_role", res.role)
                this.setState({ navigate: true, password: null, email: null });
                
            })
            .catch(err => {
                console.log(err);
            })
    }
    render() {
        if (this.state.navigate) {
            return window.location.href = '/dashboard'
        }
        else if (localStorage.getItem("x-access-token")) {
            return window.location.href = '/dashboard'
        }
        return (
            <div className='login_page'>
                <Grid centered>
                    <Grid.Column>
                        <Segment raised className='login_seg'>
                            <div className='login_head'>
                                <img src={logo} alt="Alpyne Logo" className='logo' />
                            </div>
                            <h2 className='login_head login_text mt-3 mb-0'>login</h2>
                            <label style={{ color: "red", fontWeight: "bold" }}>{this.state.error}</label>
                            <Form>
                                <Form.Field>
                                    <div className="login_spaces">
                                        <input
                                            type="text"
                                            placeholder="Enter your email"
                                            name="email"
                                            value={this.state.email}
                                            autoComplete="on"
                                            className="login_input"
                                            onChange={(e) => this.validateEmail(e)} />
                                    </div>
                                </Form.Field>
                                <Form.Field>
                                    <div className="login_spaces" style={{ display: "flex", marginLeft: 0 }}>
                                        <input
                                            type={this.state.isShown ? "text" : "password"}
                                            placeholder="Password"
                                            name="password"
                                            value={this.state.password}
                                            autoComplete="off"
                                            className="login_input"
                                            onChange={(e) => this.PasswordHandler(e)} />
                                        <div>
                                            <button style={{ color: "#CC00FF", background: 'white', border: "1px solid white", marginLeft: -60, marginTop: '15px', fontFamily: "Inter-Medium" }} onClick={this.togglePassword}>{this.state.Text}</button>
                                        </div>
                                    </div>
                                </Form.Field>
                                <div className='login_spaces login_head'>
                                    {this.state.emailError ?
                                        <button className="login_btn_hover" onClick={this.handleVerifyEmail}>Login</button> :
                                        <button className="login_btn_hover disabled_button ">Login</button>
                                    }</div>
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}


