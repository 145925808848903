import React, { Component } from 'react'
import { Service } from "../providers/service";
import { Spinner, Row, Col, Table, Input, InputGroup, InputGroupText } from "reactstrap"
import DataTable from 'react-data-table-component';
import { Button, Icon } from 'semantic-ui-react';
import searchlogo from "../assets/images/search.png"
import { IoShareOutline } from 'react-icons/io5';
import { CSVLink } from "react-csv";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import {IoMdArrowDropdown} from 'react-icons/io';

// const dummydata = [
//   {
//   _id : "123hs4d",
//   name :"alpyne",
//   createdAt :"2022-11-24T14:09:06.074Z",
//   order_amount : 1000,
//   price : 10,
//   chain : 0,
//   status: 0,
//   side : 0,
//   address : "1234567980abcdefghaaaa",
//   txid : "searching"

// },
// {
//   _id : "123hs4d",
//   createdAt :"2022-11-24T14:09:06.074Z",
//   order_amount : 1100,
//   price : 10,
//   chain : 1,
//   status: 1,
//   side:0,
//   address : "1234567980abcdefgh",
//   txid : "62y772766387h98883hhhhskkkkhhcbbbbcbbbbbbbbbbjs"

// },

// ]

export default class Transactions extends Component {
  constructor() {
    super();
    this.state = {
      value: '',
      txndata: [],
      loaded: false,
      search: '',
      export_data:"",
      clientDropDown:"Delta Exchange",
      view:"",
      userdata:"",
      };
  }
  async componentDidMount() {
    await Service.getUser()
    .then(response => {
      this.setState({ userdata: response })
    })
    .catch(error => {
      console.log(error);
      if(error.response.status == 401){
        Service.Logout()
      }
    })

    await Service.getTrans()
      .then(response => {
        console.log("trnx response", response);
        //for export data 
        let Export_data = response.map(item =>{
          let _created_IST_Date = new Date(item.createdAt);
          let created_ist_time = _created_IST_Date.toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata' ,hour12: false  });
          let created_ist_date = _created_IST_Date.toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata' ,  year: 'numeric', month: '2-digit', day: '2-digit' });
          let updated_IST_Date = new Date(item.updatedAt);
          let updated_ist_time = updated_IST_Date.toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata' ,hour12: false  });
          let updated_ist_date = updated_IST_Date.toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata' ,  year: 'numeric', month: '2-digit', day: '2-digit' });
          return {tx_type:item.tx_type == 0 ? "vac_mode" : item.tx_type == 1 ? "upi cashfree" : item.tx_type == 2 ? "upi dbs" : " vac mode",side:item.side == 0 ? "buy" : "sell",chain : item.chain == 0 ? "Tron" : item.chain == 1 ? "Ethereum" : item.chain == 2 ? "Polygon" : "tron",token: item.token == 0 ? "usdt" : item.token == 1 ? "ps1" : item.token == 2 ? "tusd" : "usdt",
          order_amount:item.order_amount ,
          status: item.side == 0 ? (item.status == 0 ? "pending" : item.status == 1 ? "inr recieved" : item.status == 2 ? "blockchain txn done" : item.status == 3 ? "timeout" : item.status == 4 ? "invalid account" : "pending" ): (item.status == 0 ? "pending" : item.status == 1 ? "blockchain txn done" : item.status == 2 ? "inr transfer complete" : item.status == 3 ? "incorrect otp" : item.status == 4 ? "locked" : "pending"),
          tranx_id : item._id , wallet_address : item.address ,price:item.price,user_id : item.user,createdAt :` ${created_ist_date} ${created_ist_time}`,
          updatedAt:` ${updated_ist_date} ${updated_ist_time}`, 
          blockchain_id : item.txid ,
        }
        })

        //for admin pannel
        const filteredData = response.filter((item) => item.status !== 3);
        // console.log("filterdata" , filteredData);
        const updatedData = filteredData.map((item) => {
          switch (item.status) {
            case 0:
              return { ...item, status: 'Pending' };
            case 1:
              return { ...item, status: 'Initiated' };
            case 2:
              return { ...item, status: 'Completed' };
            case 3:
              return { ...item, status: 'Timeout' };
            default:
              return item;
          }
        });
        // console.log("updated data",updatedData);
        this.setState({ export_data : Export_data == null ? "api data empty" : Export_data ,  loaded: true,view: localStorage.getItem('environment') },()=>{
          // console.log("user data is",this.state.userdata);
          // console.log("trxn data is", this.state.txndata);
          const updatedData2 = updatedData.map(data => {
            const matchingVendor = this.state.userdata.find(vendor => vendor._id === data.user);
            // console.log(matchingVendor);
            return {
              ...data,
              vendor_user_id: matchingVendor ? matchingVendor.vendor_user_id : null
            };
          });
          this.setState({  txndata: updatedData2  });
          
        //   let users = 0;
        //   let data = updatedData2.map(item => {
        //     if(item.status == 2){
        //         //  =  + 1;
        //         let startedtime = new Date(item.createdAt);
        //         let time = startedtime.getMinutes();
        //         console.log(time);
        //     } 
        // })
        

        })
      })
      .catch(error => {
        console.log(error);
        if(error.response.status == 401){
          Service.Logout()
        }
      })
  }

  DeltaDropDown = () =>{
    this.setState({clientDropDown:"Delta Exchange"})
  }

  polysportsDropDown = () =>{
      this.setState({clientDropDown:"Polysposrts"})
  }

  plenaDropDown = () =>{
      this.setState({clientDropDown:"Plena"});
  }

  syptoDropDown = () =>{
      this.setState({clientDropDown:"Sypto"});
  }

  handleInput = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  formatDate=(timestamp)=>{
    // console.log(timestamp)
    // return new Date(timestamp).toLocaleString()
    // // let d = new Date(timestamp*1000);
    // // return `${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`
    // // let splitString = date.split("");
    // // let reverseArray = splitString.reverse();
    // // let DatejoinArray = reverseArray.join("");
    // console.log("timestamp", timestamp);
    let IST_Date = new Date(timestamp);
    let ist_time = IST_Date.toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata' ,hour12: false  });
    let ist_date = IST_Date.toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata' ,  year: 'numeric', month: '2-digit', day: '2-digit' });
    // console.log(ist_time);
    // console.log(ist_date);
    // let date = timestamp.slice(0,10);
    // let time = timestamp.slice(11,timestamp.length-5);
    return `${ist_date}  ${ist_time}`
  }

  handleName_UserId = (id,name) =>{
    if (name != null && id != null ) {
      return name;
    } else if(name == null) {
      return id
    } else {
      return name;
    }
  }

  render() {
    const { loaded, txndata, search } = this.state;
    const filteredData = loaded && txndata.filter(item => item.address && item.address == search || item.vendor_user_id && item.vendor_user_id.includes(search.toLowerCase()) || item._id && item._id.includes(search.toLowerCase()) || item.txid && item.txid.includes(search.toLowerCase())  || item.createdAt && item.createdAt.includes(search.toLowerCase()) || item.order_amount.toString() && item.order_amount.toString().includes(search.toLowerCase())   );

    const txnColumns = [
      
      {
        name: 'Vendor User ID',
        // cell: (filteredData) => <div style={{textAlign:"center"}}>{this.handleName_UserId(filteredData._id,filteredData.name)}</div>,
        selector: 'vendor_user_id',
        sortable: true,
        center: true,
        width: '12%'
      },
      {
        name: 'Time',
        cell: (filteredData) => <div style={{whiteSpace:"pre-line",textAlign:"center"}}>{this.formatDate(filteredData.createdAt)}</div>,
        selector:"createdAt",
        sortable: true,
        center: true,
        width: '10%',
      },
      {
        name: 'Amount (INR)',
        selector: 'order_amount',
        sortable: true,
        center: true,
        width: '11%'
      },
      {
        name: 'Currency',
        cell: () => <div>USDT</div>,
        sortable: true,
        center: true,
        width: '8%'
      },
      {
        name: 'Currency Bought',
        cell: (filteredData) => <div>{(filteredData.order_amount / filteredData.price).toFixed(2)}</div>,
        // selection:'currency_bought',
        sortable: true,
        center: true,
        width: '14%'
      },
      {
        name: 'Chain',
        cell: (row) => row.chain == 0 ? "Tron" : row.chain == 1 ? "Ethereum" : row.chain == 2 ? "Polygon" : "undefined" ,
        sortable: true,
        center: true,
        width: '9%'
      },
      {
        name: 'Status',
        
        cell: (filteredData) =>
          filteredData.status == "Pending" ? <div className="trans_circle trans_yellow">Pending</div> :
            filteredData.status == "Initiated" ? <div className="trans_circle trans_green">Initiated</div> :
              filteredData.status == "Completed" ? <div className="trans_circle trans_green">Completed</div> :
                filteredData.status == "Timeout" ? <div className="trans_circle trans_red">Timeout</div> :
                  <div className="trans_circle trans_red">Failed</div>,
        selector:"status",
        sortable: true,
        center: true,
        width: '10%',
        ignoreRowClick: true
      },
      {
        name: 'Address',
        cell: (filteredData) => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}><u style={{textDecoration:"none"}}>{filteredData.address}</u></div>,
        selector: 'address',
        sortable: true,
        center: true,
        width: '13%'
      },
      {
        name: 'Transaction ID',
        cell: (filteredData) => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}><u style={{textDecoration:"none"}}>{filteredData.txid}</u>{filteredData.txid &&
          <CopyToClipboard text={filteredData.txid}
            onCopy={() => { toast.success('Transaction ID successfully copied to clipboard!') }}>
            <Icon name='copy' style={{ fontSize: '15px', color: '#CC00FF' }} />
          </CopyToClipboard>}</div>,
        sortable: true,
        center: true,
        width: '13%'
      }
    ]

    const customStyles = {
      headCells: {
        style: {
          fontSize: '15px',
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: 'black',
        }
      },
      pagination: {
        style: {
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '15px'
        },
        rows: {
          style: {
              textAlign : 'left', // override the row height
          },
      },
        // pageButtonsStyle: {
        //   borderRadius: '50%',
        //   height: '40px',
        //   width: '40px',
        //   padding: '8px',
        //   margin: 'px',
        //   cursor: 'pointer',
        // },
      },
    };
  //   const CustomMaterialPagination = ({ rowsPerPage:{"10"}, rowCount, onChangePage, onChangeRowsPerPage, currentPage }) => (
  //     <TablePagination
  //         component="nav"
  //         count={rowCount}
  //         rowsPerPage={50}
  //         page={currentPage - 1}
  //         onChangePage={onChangePage}
  //         onChangeRowsPerPage={({ target }) => onChangeRowsPerPage(Number(target.value))}
  //         ActionsComponent={TablePaginationActions}
  //     />
  // );


    return (
      <div className='pt-5 pb-5 p-4'>
        <Row>
          <Col sm="8" md="9" lg="4" xl="4">
            <InputGroup>
              <Input type="text" name='search' value={search} placeholder='Search...' onChange={this.handleInput} className='search_input' />
              <InputGroupText style={{ backgroundColor: 'white', border: 'none' }}>
                <img src={searchlogo} />
              </InputGroupText>
            </InputGroup>
          </Col>
          
          {this.state.view == "adm" ? 
          <Col sm="4" md="3" lg="8" xl="8" style={{textAlign:"end"}}>
          <div class="dropdown" style={{minWidth:194}} >
                            <span style={{color:"#595959"}}>{this.state.clientDropDown} <IoMdArrowDropdown /></span>
                            <div class="dropdown-content" style={{minWidth:190}}>
                            <p style={{color:"#595959",textAlign:"center"}}  onClick={ this.DeltaDropDown} >Delta Exchange</p>           {/* //onClick={this.handleDropDown} */}
                            
                            <p style={{color:"#595959",textAlign:"center"}}  onClick={this.polysportsDropDown} >Polysports</p>
                            <p style={{color: "#595959",textAlign:"center"}} onClick={this.plenaDropDown} >Plena</p>
                            <p style={{color: "#595959",textAlign:"center"}}  onClick={this.syptoDropDown}  >Sypto</p>
                            </div>
                            </div>
            <CSVLink data={this.state.export_data} filename={"transactions.csv"}><Button className='export_botton'>Export <IoShareOutline /></Button></CSVLink>
          </Col> : <Col sm="4" md="3" lg="8" xl="8" style={{textAlign:"end"}}> <CSVLink data={this.state.export_data} filename={"transactions.csv"}><Button className='export_botton'>Export <IoShareOutline /></Button></CSVLink></Col>}
        </Row>
        {loaded ?
          <DataTable
            columns={txnColumns}
            data={filteredData}
            pagination
            paginationPerPage={50}
            paginationRowsPerPageOptions={[50, 100, 150, 200]}
            highlightOnHover
            customStyles={customStyles}
            paginationComponentOptions={{ rowsPerPageText: 'Rows per page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: true, selectAllRowsItemText: 'All' }}
            className='rtd mt-4'
          /> :
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='pt-5'><Spinner /></div>}
      </div>
    );
  }
}
