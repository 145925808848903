import React, { Component } from 'react'
import { Button} from 'reactstrap'
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import { Service } from '../providers/service';
import { Row, Col} from "reactstrap"
import { IoShareOutline } from 'react-icons/io5';
import {IoMdArrowDropdown} from 'react-icons/io';
import userlogo from "../assets/images/dashUser.png";
import usertransaction from "../assets/images/dashtransaction.png";
import dollar from "../assets/images/dashDollar.png";
import {CiTimer} from 'react-icons/ci';
import {BsInfoCircle} from 'react-icons/bs';

// import logo from "../assets/images/logo.png";
// import { Link } from 'react-router-dom';
// import { CSVLink } from "react-csv";
// import { jsPDF } from "jspdf";
// import html2canvas from 'html2canvas';

export default class Dashboard extends Component {
    constructor() {
        super();
        this.myRef = React.createRef(null);
        this.state = {
            time_delta: 24,
            time_delta_text: "24 hours",
            dashdata: null,
            loaded: false,
            Successful_Unique_Users :"fetching...",
            Total_Successful_Transaction:"fetching...",
            Successful_Transaction_Volume:"fetching...",
            Timeout_Unique_Users:"fetching...",
            Total_Timeout_Transaction:"fetching...",
            Timeout_Transaction_Volume:"fetching...",
            timeDropDown : "Past 1 year",
            timeDropDown_graph2:"Past 1 year",
            transaction_Count_graph:"Past 1 year",
            // SuccessMetricsExport : "",   
            optionDatesData1 : "",
            valuesSeriesdata1 :"",
            valuesSeriesdata2:"",
            optionDatesData2 : "",
            txnCount_Dates:"",
            txnCount_values:"",
            view:"",
            clientDropDown:"Delta Exchange",
            maxRange_Graph1:"100000",
            maxRange_Graph2:"100000",
            maxRange_txnCount:"100000",
            final_avg_time:"fetching...",
            drop_clicked: false,
            drop_clicked_2: false,
            oneDay : false ,
            graph2_oneDaychart:false,
            txnCount_Oneday_Chart:false,
            series: "",
              options: "",
              series2: "",
              options2: "",
        };
        };

         
        async componentDidMount() {
            let START_TIME = new Date().getTime() ;
            let TIME_DELTA = 365*24*3600*1000;
            
            // calling 365 date data to show initially on the screen on first load 

            

        //hitting dashboard api's
            await Service.getDashboard(START_TIME,TIME_DELTA)
                .then(res=>{
                    // console.log(res); 
                    let optionDatesData1 = res.graph.success.dates.slice((res.graph.success.values.length - 365), res.graph.success.values.length);
                    // console.log(optionDatesData1)
                    let valuesSeriesdata1 = res.graph.success.values.slice((res.graph.success.values.length - 365), res.graph.success.values.length).map(item => parseInt(item));
                    

                    let optionDatesData2 = res.graph.timeout.dates.slice(-365);
                    // console.log(optionDatesData2)
                    // let valuesSeriesdata2 = res.graph.timeout.values.slice(-365);
                    let valuesSeriesdata2 = res.graph.timeout.values.slice((res.graph.timeout.values.length - 365), res.graph.timeout.values.length).map(item => parseInt(item));
                    // console.log(valuesSeriesdata2)
                    // let timeoutgraphData = res.graph.timeout.values.map(value => Math.round(value) );

                    // let s_tcount_dates = res.count_graph.success.dates.slice((res.count_graph.success.values.length - 365), res.count_graph.timeout.values.length);
                    console.log(optionDatesData1)
                    // let s_tcount_values = res.count_graph.success.values.slice((res.count_graph.success.values.length - 365), res.count_graph.success.values.length).map(item => parseInt(item));

                    // console.log(s_tcount_dates);
                    // console.log(s_tcount_values);

                    let avg_Time_seconds = res.average_tx_time;
                    let seconds = Math.floor(avg_Time_seconds / 1000);
                    let hours = Math.floor(seconds / 3600);
                    let minutes = Math.floor((seconds % 3600) / 60);
                    let remainingSeconds = seconds % 60;

                    let formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
                    // console.log(formattedTime);


                    this.setState({ 
                        Successful_Unique_Users: res.users_success_orders_placed.toLocaleString('en-IN'), Successful_Transaction_Volume : res.success_inr.toLocaleString('en-IN') ,
                        Total_Successful_Transaction : res.success_orders.toLocaleString('en-IN') ,
                        Timeout_Unique_Users : res.users_timedout_orders_placed.toLocaleString('en-IN') ,
                        Total_Timeout_Transaction: res.timed_out_orders.toLocaleString('en-IN') ,
                        Timeout_Transaction_Volume : Math.round(res.timed_out_inr).toLocaleString('en-IN') ,
                        series : res.graph.success.values ,
                        options:res.graph.success.dates,
                        series2: res.graph.timeout.values,
                        options2:res.graph.timeout.dates,
                        view:localStorage.getItem('environment'), 
                        // // s_tcount_dates:s_tcount_dates,
                        // // s_tcount_values:s_tcount_values,
                        final_avg_time: formattedTime},()=>{
                        
                        let oneYearGraph = this.OneYear(optionDatesData1,valuesSeriesdata1);
                        let oneYearGraph2 = this.OneYear(optionDatesData2,valuesSeriesdata2);
                        // let oneYearTxn_Count = this.OneYear(s_tcount_dates,s_tcount_values);
                        let YaxisHandle = this.YaxisRange(oneYearGraph.arr);
                        let YaxisHandleGraph2 = this.YaxisRange(oneYearGraph2.arr);
                        // let YaxisHandle_txnCount = this.YaxisRange(oneYearTxn_Count.arr);
                        this.setState({valuesSeriesdata1 : oneYearGraph.arr,
                            optionDatesData1:oneYearGraph.dateArr,
                            valuesSeriesdata2:oneYearGraph2.arr,
                            optionDatesData2:oneYearGraph2.dateArr,
                            maxRange_Graph1:YaxisHandle,
                            maxRange_Graph2:YaxisHandleGraph2 ,
                            // maxRange_txnCount:YaxisHandle_txnCount,
                            // txnCount_Dates:oneYearTxn_Count.dateArr,
                            // txnCount_values:oneYearTxn_Count.arr
                        });
                    });
                })
                .catch(err => { console.log(err)
                    // Service.Logout()
                    if(err.status == 401 || err.response.status == 401){
                        console.log("inside if statement");
                        Service.Logout()
                      }
                } )

                
    }

    OneYear=(optionDatesData1,valuesSeriesdata1)=>{
        let arr=[];
        let dateArr = [];
        let sum = 0;
        let gap = 7;
        for(let key =0;key<=valuesSeriesdata1.length;key++){
            sum = sum + valuesSeriesdata1[key];
            if(key%gap == 0){
                arr.push(sum);
                dateArr.push(optionDatesData1[key])
                sum = 0;
            }
        }
        if (arr.slice(-1) == "NaN"  ){
            arr.pop();
        } 
        if(dateArr.slice(-1) == "undefined" ){
          dateArr.pop();
        }
        
        return {arr,dateArr};
    }
    
//for exporting the whole page of dashboard
    // export = () =>{
        // const { image, takeScreenshot, isLoading, clear } = takeScreenshot({ref:myRef});
        // console.log("export button pressed");
        // const doc = new jsPDF();
        // const take = document.getElementById("dashboard-area")
        // html2canvas(take,{logging: true, letterRendering:1, useCORS:true}).then(canvas => {
        //     const imageWidth = 208;
        //     const imageHeight = canvas.height * imageWidth / canvas.width;
        //     const  imageData = canvas.toDataURL('img/png');
        //     doc.addImage(imageData,'PNG',0,0,imageWidth,imageHeight);
        //     doc.save("a4.pdf"); 
        // })
       
    // }

    YaxisRange = (values)=>{
        let  max = Math.max.apply(null,values);
        let factor = Math.pow(10, Math.floor(Math.log(Math.abs(max)) / Math.LN10)); 
        if (factor == 0) { factor = 1; }   
        var maximumRange = Math.ceil(max / (factor * 1.00)) * factor; 
        return maximumRange;
    }

    DeltaDropDown = () =>{
        this.setState({clientDropDown:"Delta Exchange"})
    }

    polysportsDropDown = () =>{
        this.setState({clientDropDown:"Polysposrts"})
    }

    plenaDropDown = () =>{
        this.setState({clientDropDown:"Plena"});
    }

    syptoDropDown = () =>{
        this.setState({clientDropDown:"Sypto"});
    }
//for 1st graph
    handleDropDown365 = ()=>{
        let TIME_DELTA_ = 365*24*3600*1000;
        this.setState({timeDropDown:"Past 1 year",drop_clicked:true,oneDay : false});
        this.change_cardData(TIME_DELTA_);
        this.changeGraph(365);
    }
    
    handleDropDown07=() =>{
        let TIME_DELTA_ = 7*24*3600*1000;
        this.setState({timeDropDown:"Past 7 days",drop_clicked:true,oneDay : false});
        this.change_cardData(TIME_DELTA_);
        this.changeGraph(7);
    }
    handleDropDown30 =() =>{
        let TIME_DELTA_ = 30*24*3600*1000;
        this.setState({timeDropDown:"Past 30 days",drop_clicked:true,oneDay : false});
        this.change_cardData(TIME_DELTA_);
        this.changeGraph(30);
    }
    handleDropDown1= () =>{
        let TIME_DELTA_ = 1*24*3600*1000;
        this.setState({timeDropDown:"Past 24 hours",drop_clicked:true});
        this.change_cardData(TIME_DELTA_);
        this.changeGraph(1);
        this.setState({oneDay : true });
    }
// for 2nd graph 
    handleDropDowngraph_2_07=() =>{
        let TIME_DELTA_ = 7*24*3600*1000;
        this.setState({timeDropDown_graph2:"Past 7 days",drop_clicked_2:true,graph2_oneDaychart : false});
        this.change_cardData_2(TIME_DELTA_);
        this.changeGraph2(7);
    }
    handleDropDowngraph_2_30 =() =>{
        let TIME_DELTA_ = 30*24*3600*1000;
        this.setState({timeDropDown_graph2:"Past 30 days",drop_clicked_2:true,graph2_oneDaychart : false});
        this.change_cardData_2(TIME_DELTA_);
        this.changeGraph2(30);
    }
    handleDropDowngraph_2_1= () =>{
        let TIME_DELTA_ = 24*3600*1000;
        this.setState({timeDropDown_graph2:"Past 24 hours",drop_clicked_2:true});
        this.change_cardData_2(TIME_DELTA_);
        this.changeGraph2(1);
        this.setState({graph2_oneDaychart : true });
    }
    handleDropDowngraph_2_365 = ()=>{
        let TIME_DELTA_ = 365*24*3600*1000;
        this.setState({timeDropDown_graph2:"Past 1 year",drop_clicked_2:true,graph2_oneDaychart : false});
        this.change_cardData_2(TIME_DELTA_);
        this.changeGraph2(365);
    }


    //3rd graph


    txn_count_drop_07=() =>{
        let TIME_DELTA_ = 7*24*3600*1000;
        this.setState({transaction_Count_graph:"Past 7 days",txnCount_Oneday_Chart : false});
        this.TransactionCount_Graph(7);
    }
    txn_count_drop_30 =() =>{
        let TIME_DELTA_ = 30*24*3600*1000;
        this.setState({transaction_Count_graph:"Past 30 days",txnCount_Oneday_Chart : false});
        this.TransactionCount_Graph(30);
    }
    txn_count_drop_1= () =>{
        let TIME_DELTA_ = 24*3600*1000;
        this.setState({transaction_Count_graph:"Past 24 hours",txnCount_Oneday_Chart : true});
        this.TransactionCount_Graph(1);
        this.setState({txnCount_Oneday_Chart : true });
    }
    txn_count_drop_365 = ()=>{
        let TIME_DELTA_ = 365*24*3600*1000;
        this.setState({transaction_Count_graph:"Past 1 year",txnCount_Oneday_Chart : false});
        this.TransactionCount_Graph(365);
    }



    change_cardData = async(TIME_DELTA_)=>{
            let START_TIME = new Date().getTime() ;
            let TIME_DELTA = TIME_DELTA_;
        
            this.setState({ 
                Successful_Unique_Users: "fetching...",
                 Successful_Transaction_Volume :"fetching..."  ,
                Total_Successful_Transaction :"fetching..."  ,
               
              });

            await Service.getDashboard(START_TIME,TIME_DELTA)
                .then(res=>{
                    // console.log("response for upper cards",res)

                    this.setState({ 
                        Successful_Unique_Users: res.users_success_orders_placed.toLocaleString('en-IN'), Successful_Transaction_Volume : res.success_inr.toLocaleString('en-IN') ,
                        Total_Successful_Transaction : res.success_orders.toLocaleString('en-IN') ,
                       
                      });
                })
                .catch(err => { console.log(err)
                    if(err.status == 401 || err.response.status == 401){
                        Service.Logout()
                      }
                } )
                .finally(()=>{
                    this.setState({ drop_clicked: false });
                })
    }


    //writing this similar function saperately so while setState the data  will only change for second graph on click on 2nd dropDown
    change_cardData_2 = async(TIME_DELTA_)=>{
        let START_TIME = new Date().getTime() ;
        let TIME_DELTA = TIME_DELTA_;
        this.setState({ 
            Timeout_Unique_Users : "fetching...",
            Total_Timeout_Transaction:"fetching..."  ,
            Timeout_Transaction_Volume : "fetching...",
          });
        await Service.getDashboard(START_TIME,TIME_DELTA)
            .then(res=>{
                // console.log("response lower cards",res);
                this.setState({ 
                    Timeout_Unique_Users : res.users_timedout_orders_placed.toLocaleString('en-IN') ,
                    Total_Timeout_Transaction: res.timed_out_orders.toLocaleString('en-IN') ,
                    Timeout_Transaction_Volume : Math.round(res.timed_out_inr).toLocaleString('en-IN') ,
                  });
            })
            .catch(err => { console.log(err)
                if(err.status == 401 || err.response.status == 401){
                    Service.Logout()
                  }
            } )
            .finally(()=>{
                this.setState({ drop_clicked_2: false });
            })
}


changeGraph = (timeline) => {
    let valuesSeriesdata1 = this.state.series;
    let optionDatesData1 = this.state.options;
    let value1_1 = valuesSeriesdata1.slice((valuesSeriesdata1.length - timeline), valuesSeriesdata1.length)
    let options1_1 =  optionDatesData1.slice((optionDatesData1.length - timeline), optionDatesData1.length);
    let XaxisRange = this.OneYear(options1_1,value1_1);
    let maxRange = timeline == "365" ? this.YaxisRange(XaxisRange.arr) :this.YaxisRange(value1_1) ;
    this.setState({ valuesSeriesdata1: timeline =="365" ? XaxisRange.arr :  value1_1 ,optionDatesData1: timeline == "365" ? XaxisRange.dateArr :  options1_1 ,maxRange_Graph1:maxRange});
   
}
changeGraph2 = (timeline) => {
    let valuesSeriesdata2 = this.state.series2;
    let optionDatesData2 = this.state.options2;
    let value2_1 =   valuesSeriesdata2.slice((valuesSeriesdata2.length - timeline), valuesSeriesdata2.length);
    let options2_1 = optionDatesData2.slice((optionDatesData2.length - timeline), optionDatesData2.length);
    let XaxisRange = this.OneYear(options2_1,value2_1);
    let maxRange = timeline == "365" ? this.YaxisRange(XaxisRange.arr) : this.YaxisRange(value2_1);
    this.setState({ valuesSeriesdata2: timeline =="365" ? XaxisRange.arr : value2_1 ,optionDatesData2: timeline == "365" ? XaxisRange.dateArr : options2_1 ,maxRange_Graph2:maxRange});
   
}

TransactionCount_Graph = (timeline) => {
    let valuesSeriesdata2 = this.state.txnCount_values;
    let optionDatesData2 = this.state.txnCount_Dates;
    let value2_1 =   valuesSeriesdata2.slice((valuesSeriesdata2.length - timeline), valuesSeriesdata2.length);
    let options2_1 = optionDatesData2.slice((optionDatesData2.length - timeline), optionDatesData2.length);
    let XaxisRange = this.OneYear(options2_1,value2_1);
    let maxRange = timeline == "365" ? this.YaxisRange(XaxisRange.arr) : this.YaxisRange(value2_1);
    this.setState({ txnCount_values: timeline =="365" ? XaxisRange.arr : value2_1 ,txnCount_Dates: timeline == "365" ? XaxisRange.dateArr : options2_1 ,maxRange_txnCount:maxRange});
   
}

  
    render() {
        const {valuesSeriesdata2} = this.state;
        let maximum_Graph1 =  Math.max(this.state.maxRange_Graph1);
        let maximum_Graph2 =  Math.max(this.state.maxRange_Graph2);
        let maximum_txnCnt =  Math.max(this.state.maxRange_txnCount);

        return (
           <div id='dashboard-area' style={{padding:20,overflowX:"auto"}}>
                <div style={{padding:"10px 5px 100px 5px"}}>

           
                    <Row >
                        <Col sm="8" md="9" lg="6" xl="6">
                            <p style={{fontSize:32,fontWeight:"bold"}}>Success Metrics</p>
                        </Col>
                        <Col sm="4" md="3" lg="6" xl="6" >

                            <div style={{display:"flex", justifyContent:"flex-end",flexWrap:"wrap"}}>
                            {this.state.view == "adm" ?  <div class="dropdown" style={{minWidth:194}} >
                            <span style={{color:"#595959"}}>{this.state.clientDropDown} <IoMdArrowDropdown /></span>
                            <div class="dropdown-content" style={{minWidth:190}}>
                            <p style={{color:"#595959",textAlign:"center"}}  onClick={ this.DeltaDropDown} >Delta Exchange</p> 
                                      {/* //onClick={this.handleDropDown} */}
                            
                            <p style={{color:"#595959",textAlign:"center"}}  onClick={this.polysportsDropDown} >Polysports</p>
                            <p style={{color: "#595959",textAlign:"center"}} onClick={this.plenaDropDown} >Plena</p>
                            <p style={{color: "#595959",textAlign:"center"}}  onClick={this.syptoDropDown}  >Sypto</p>
                            </div>
                            </div> : <></> }
                           
                            
                            <div class="dropdown" style={{minWidth:194}}>
                            <span style={{color:"#595959",}}>{this.state.timeDropDown} <IoMdArrowDropdown /></span>
                            <div class="dropdown-content" style={this.state.drop_clicked ? {display:"none"} : {}} >
                            <p style={{color:"#595959"}} onClick={this.handleDropDown1} >Past 24 hours</p>
                            <p style={{color:"#595959"}} onClick={this.handleDropDown07} >Past 7 days</p>
                            <p style={{color: "#595959"}} onClick={this.handleDropDown30}>Past 30 days</p>
                            <p style={{color: "#595959"}}  onClick={this.handleDropDown365} >Past 1 year</p>
                            </div>
                            </div>
                            
                            {/* <div  style={{textAlign:"end"}} >
                            
                            </div> */}
                                <div  >

                            {/* <CSVLink data={"hello"} filename={"transactions.csv"}> */}
                            {/* <Button className='export_botton' onClick={this.export} >Export <IoShareOutline /></Button> */}
                            {/* </CSVLink> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{display:"flex",flexWrap:"wrap",width:'95%'}}>
                    <Col sm="4" md="6" lg="4" xl="3" >
                        <div className='Dashboardcard'>
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                                <div style={{display:"flex",flexDirection:"column",justifyContent:"space-evenly" }}>
                                    <div className="dashCardHead" >
                                        Successful Unique Users
                                        <br />
                                    </div>
                                    <br />
                                    <div className="dashCardquantity" >
                                    {this.state.Successful_Unique_Users}
                                    </div>
                                </div>
                                <div className='dashLogos'>
                                <img src={userlogo} alt="" style={{width:50,height:50}} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4" md="6" lg="4" xl="3" >
                    <div className='Dashboardcard'>
                            <div style={{display:"flex",justifyContent:"space-between",flexWrap:"wrap"}}>
                                <div style={{display:"flex",flexDirection:"column",justifyContent:"space-evenly" }}>
                                    <div className="dashCardHead" >
                                        Total Successful Transaction
                                        <br />
                                    </div>
                                    <br />
                                    <div className="dashCardquantity" >
                                        {this.state.Total_Successful_Transaction}
                                    </div>
                                </div>
                                <div className='dashLogos'>
                                <img src={usertransaction } alt="" style={{width:50,height:50}} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4" md="6" lg="4" xl="3" >
                    <div className='Dashboardcard'>
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                                <div style={{display:"flex",flexDirection:"column",justifyContent:"space-evenly" }}>
                                    <div className="dashCardHead" >
                                    Successful Transaction Volume
                                        <br />
                                    </div>
                                    <br />
                                    <div className="dashCardquantity" >
                                        {this.state.Successful_Transaction_Volume}
                                    </div>
                                </div>
                                <div className='dashLogos'>
                                <img src={dollar} alt="" style={{width:50,height:50}} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4" md="6" lg="3" xl="3" >
                    <div className='Dashboardcard'>
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                                <div style={{display:"flex",flexDirection:"column",justifyContent:"space-evenly" }}>
                                    <div className="dashCardHead"  >
                                        Average Transaction Time 
                                        <div class="tooltip-container" >
                                        <div className='timeoutInfo2' >
                                        <BsInfoCircle size={18} />
                                        </div>
                                        <div class="tooltip2">Average transaction time is the average time taken by the users to complete a transaction. It starts from the time when user places the order and ends when the crypto has been credited to his wallet.</div>
                                
                                </div>
                                        
                                        
                                    </div>
                                    <br />
                                    <div className="dashCardquantity" style={this.state.final_avg_time == "fetching..." ?  {fontSize:32} : {fontSize:22}}>
                                        {this.state.final_avg_time}
                                    </div>
                                </div>
                                <div className='dashLogos'>
                                <CiTimer size={45} color="grey"/>
                                </div>
                            </div>
                        </div>
                    </Col>
                    </Row>
                    <Row>
                    <div className='dashboard-graph'>
                        <div className='dashboard-chart' style={{marginLeft:1}}>
                            
                            <div className='dashboard-c'>
                { !this.state.oneDay ?  <ReactApexChart options={{
                                title: {
                                text: 'Successful Transaction Volume',style: {fontWeight: 600,fontSize:18,}, },
                                colors:["#CC00FF"],
                                chart: {toolbar: {show: true,offsetX: 0,offsetY: 0,tools: {download: false,selection: true,zoom: true,zoomin: true,
                                        zoomout: true,reset: true | '<img src="/static/icons/reset.png" width="20">',pan: true,},},
                                    events: {beforeMount : (chart) =>{chart.windowResizeHandler()},},height: 350,width: '100%',type: 'area'},
                                    dataLabels: {enabled: false},
                                    stroke: {curve: 'smooth'},
                    xaxis: {categories: [...this.state.optionDatesData1]},
                    yaxis: {
                        
                        min: 0,
                        max:maximum_Graph1,
                        labels: {
                            formatter: function(val) {
                                return val.toLocaleString('en-IN');
                            }
                        },
                    title: {text: "Transaction volume",
                      style: {color: "#595959",fontSize: '16px',fontWeight: 500,marginTop:40,cssClass: 'apexcharts-xaxis-title',}, },
                    min: 0,},
                    tooltip: {
                    x: {format: 'dd MMM yyyy'}},
                            }}
                             series={[ {name: 'INR',data:[...this.state.valuesSeriesdata1],}
                            ]} type="area"  height={350}  />
                            
                             : 
                            
                            <Chart
                                options={{
                                    plotOptions: {
                                        bar: {
                                        columnWidth: "2%", 
                                        },
                                    },
                                    
                                title: {
                                text: 'Successful Transaction Volume',style: {fontWeight: 600,fontSize:18,}, },
                                colors:["#CC00FF"],
                                chart: {

                                    id: "basic-bar",
                                    toolbar: {show: false},
                                    
                                   },
                                    dataLabels: {enabled: false},
                                    
                xaxis: {categories: [...this.state.optionDatesData1]},
                yaxis: {
                    
                    labels: {
                        formatter: function(val) {
                            return val.toLocaleString('en-IN');
                        }
                    },
                    title: {text: "Transaction volume",
                      style: {color: "#595959",fontSize: '16px',fontWeight: 500,marginTop:40,cssClass: 'apexcharts-xaxis-title',}, },
                    min: 0,},
                    tooltip: {
                    x: {format: 'dd MMM yyyy'}},
                            }}
                             series={[ {name: 'INR',data:[...this.state.valuesSeriesdata1],}
                            ]}
                                type="bar"
                                width="100%"
                                height={350}
                            />
                            
                            
                            }
                            </div>
                        </div>
                        <div className='graph-header graph-x-p'  >Time</div>
                    </div>
                    
                    </Row> 
                        <br />
                        <br />
                        <br />

                    {/* // transaction count graph */}

                <div>
                    {/* <Row >
                        <Col sm="8" md="9" lg="6" xl="6">
                            <p style={{fontSize:32,fontWeight:"bold"}}>Success Transaction Count</p>
                        </Col>
                        <Col sm="4" md="3" lg="6" xl="6" >

                            <div style={{display:"flex", justifyContent:"flex-end",flexWrap:"wrap"}}>
                            <div class="dropdown" style={{minWidth:194}}>
                            <span style={{color:"#595959",}}>{this.state.transaction_Count_graph} <IoMdArrowDropdown /></span>
                            <div class="dropdown-content" style={this.state.drop_clicked ? {display:"none"} : {}} >
                            <p style={{color:"#595959"}} onClick={this.txn_count_drop_1} >Past 24 hours</p>
                            <p style={{color:"#595959"}} onClick={this.txn_count_drop_07} >Past 7 days</p>
                            <p style={{color: "#595959"}} onClick={this.txn_count_drop_30}>Past 30 days</p>
                            <p style={{color: "#595959"}}  onClick={this.txn_count_drop_365} >Past 1 year</p>
                            </div>
                            </div> */}
                            
                            {/* <div  style={{textAlign:"end"}} >
                            
                            </div> */}
                                {/* <div  > */}

                            {/* <CSVLink data={"hello"} filename={"transactions.csv"}> */}
                            {/* <Button className='export_botton' onClick={this.export} >Export <IoShareOutline /></Button> */}
                            {/* </CSVLink> */}
                                {/* </div>
                            </div>
                        </Col>
                    </Row> */}
                    
                    
                    {/* <Row>
                    <div className='dashboard-graph'>
                        <div className='dashboard-chart' style={{marginLeft:1}}>
                            
                            <div className='dashboard-c'>
                { !this.state.txnCount_Oneday_Chart ?  <ReactApexChart options={{
                                title: {
                                text: ' Transaction count',style: {fontWeight: 600,fontSize:18,}, },
                                colors:["#CC00FF"],
                                chart: {toolbar: {show: true,offsetX: 0,offsetY: 0,tools: {download: false,selection: true,zoom: true,zoomin: true,
                                        zoomout: true,reset: true | '<img src="/static/icons/reset.png" width="20">',pan: true,},},
                                    events: {beforeMount : (chart) =>{chart.windowResizeHandler()},},height: 350,width: '100%',type: 'area'},
                                    dataLabels: {enabled: false},
                                    stroke: {curve: 'smooth'},
                    xaxis: {categories: [...this.state.txnCount_Dates]},
                    yaxis: {
                        
                        min: 0,
                        max:maximum_txnCnt,
                        labels: {
                            formatter: function(val) {
                                return val.toLocaleString('en-IN');
                            }
                        },
                    title: {text: "Transaction Count",
                      style: {color: "#595959",fontSize: '16px',fontWeight: 500,marginTop:40,cssClass: 'apexcharts-xaxis-title',}, },
                    min: 0,},
                    tooltip: {
                    x: {format: 'dd MMM yyyy'}},
                            }}
                             series={[ {name: 'INR',data:[...this.state.txnCount_values],}
                            ]} type="area"  height={350}  />
                            
                             : 
                            
                            <Chart
                                options={{
                                    plotOptions: {
                                        bar: {
                                        columnWidth: "2%", 
                                        },
                                    },
                                    
                                title: {
                                text: 'Transaction count ',style: {fontWeight: 600,fontSize:18,}, },
                                colors:["#CC00FF"],
                                chart: {

                                    id: "basic-bar",
                                    toolbar: {show: false},
                                    
                                   },
                                    dataLabels: {enabled: false},
                                    
                xaxis: {categories: [...this.state.txnCount_Dates]},
                yaxis: {
                    
                    labels: {
                        formatter: function(val) {
                            return val.toLocaleString('en-IN');
                        }
                    },
                    title: {text: "Transaction volume",
                      style: {color: "#595959",fontSize: '16px',fontWeight: 500,marginTop:40,cssClass: 'apexcharts-xaxis-title',}, },
                    min: 0,},
                    tooltip: {
                    x: {format: 'dd MMM yyyy'}},
                            }}
                             series={[ {name: 'INR',data:[...this.state.txnCount_values],}
                            ]}
                                type="bar"
                                width="100%"
                                height={350}
                            />
                            
                            
                            }
                            </div>
                        </div>
                        <div className='graph-header graph-x-p'  >Time</div>
                    </div>
                    
                    </Row>  */}

                    </div>     
            </div>
            <div>

           
           <Row >
                        <Col sm="8" md="9" lg="6" xl="6">
                        <div class="d-flex" >
                            <p style={{fontSize:32,fontWeight:"bold"}}>Timeout Metrics </p> 
                                <div class="tooltip-container" >
                        
                                <div className='timeoutInfo' >

                                <BsInfoCircle size={20} />
                                </div>
                                <div class="tooltip">Timeout transactions are those transactions in which the order was placed by the user but payment wasn’t completed within 24 hours.</div>
                                
                                </div> 
                        </div>
                           
                        </Col>
                        <Col sm="4" md="3" lg="6" xl="6" >

                            <div style={{display:"flex", justifyContent:"flex-end"}}>
                            
                            <div class="dropdown" style={{minWidth:194}}>
                            <span style={{color:"#595959",}}>{this.state. timeDropDown_graph2} <IoMdArrowDropdown /></span>
                            <div class="dropdown-content" style={this.state.drop_clicked_2 ? {display:"none"} : {}}>
                            <p style={{color:"#595959"}} onClick={this.handleDropDowngraph_2_1} >Past 24 hours</p>
                            <p style={{color:"#595959"}} onClick={this. handleDropDowngraph_2_07} >Past 7 days</p>
                            <p style={{color: "#595959"}} onClick={this.handleDropDowngraph_2_30}>Past 30 days</p>
                            <p style={{color: "#595959"}}  onClick={this.handleDropDowngraph_2_365} >Past 1 year</p>
                            </div>
                            </div>
                            
                            <div  style={{textAlign:"end"}} >
                            </div>
                                <div  >

                            {/* <CSVLink data={"hello"} filename={"transactions.csv"}><Button className='export_botton'>Export <IoShareOutline /></Button></CSVLink> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    <Col sm="4" md="3" lg="3" xl="3" >
                        <div className='Dashboardcard'>
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                                <div style={{display:"flex",flexDirection:"column",justifyContent:"space-evenly" }}>
                                    <div className="dashCardHead" >
                                        Timeout Unique <br /> Users
                                        <br />
                                    </div>
                                    <br />
                                    <div className="dashCardquantity" >
                                        {this.state.Timeout_Unique_Users}
                                    </div>
                                </div>
                                <div className='dashLogos'>
                                <img src={userlogo} alt="" style={{width:50,height:50}} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4" md="3" lg="3" xl="3" >
                    <div className='Dashboardcard'>
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                                <div style={{display:"flex",flexDirection:"column",justifyContent:"space-evenly" }}>
                                    <div className="dashCardHead" >
                                        Total Timeout Transaction
                                        <br />
                                    </div>
                                    <br />
                                    <div className="dashCardquantity" >
                                        {this.state.Total_Timeout_Transaction}
                                    </div>
                                </div>
                                <div className='dashLogos'>
                                <img src={usertransaction} alt="" style={{width:50,height:50}} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4" md="3" lg="3" xl="3" >
                    <div className='Dashboardcard'>
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                                <div style={{display:"flex",flexDirection:"column",justifyContent:"space-evenly" }}>
                                    <div className="dashCardHead" >
                                    Timeout Transaction Volume 
                                        <br />
                                    </div>
                                    <br />
                                    <div className="dashCardquantity" >
                                        {this.state.Timeout_Transaction_Volume}
                                    </div>
                                </div>
                                <div className='dashLogos'>
                                <img src={dollar} alt="" style={{width:50,height:50}} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    </Row>
                    <Row>
                    <div className='dashboard-graph'>
                        <div className='dashboard-chart'>
                            
                            <div className='dashboard-c'>
                { !this.state.graph2_oneDaychart ?    <ReactApexChart options={{
                                title: {
                                text: 'Timeout Transaction Volume',style: {fontWeight: 600,fontSize:18,}, },
                                colors:["#CC00FF"],
                                chart: {toolbar: {show: true,offsetX: 0,offsetY: 0,tools: {download: false,selection: true,zoom: true,zoomin: true,
                                        zoomout: true,reset: true | '<img src="/static/icons/reset.png" width="20">',pan: true,},},
                                    events: {beforeMount : (chart) =>{chart.windowResizeHandler()},},height: 350,width: '100%',type: 'area'},
                                    dataLabels: {enabled: false},
                                    stroke: {curve: 'smooth'},
                                    xaxis: {categories: [...this.state.optionDatesData2]},
                                    yaxis: {
                                        min: 0,
                                        max:maximum_Graph2,
                                        labels: {
                                            formatter: function(val) {
                                                return val.toLocaleString('en-IN');
                                            }
                                        },
                                        title: {text: "Transaction volume",
                                        style: {color: "#595959",fontSize: '16px',fontWeight: 500,marginTop:40,cssClass: 'apexcharts-xaxis-title',}, },
                                        min: 0,
                                        },
                                    tooltip: {
                                        x: {format: 'dd MMM yyyy'}},
                                                }}
                             series={[ {name: 'INR',data:[...this.state.valuesSeriesdata2],}
                            ]} type="area"  height={350}  /> 
                            :
                            
                            <Chart
                                options={{
                                    plotOptions: {
                                        bar: {
                                        columnWidth: "2%", 
                                        },
                                    },
                                    
                                title: {
                                text: 'Successful Transaction Volume',style: {fontWeight: 600,fontSize:18,}, },
                                colors:["#CC00FF"],
                                chart: {

                                    id: "basic-bar",
                                    toolbar: {show: false},
                                    
                                   },
                                    dataLabels: {enabled: false},
                                    
                                xaxis: {categories: [...this.state.optionDatesData2]},
                                yaxis: {
                                    
                                    labels: {
                                        formatter: function(val) {
                                            return val.toLocaleString('en-IN');
                                        }
                                    },
                                    title: {text: "Transaction volume",
                                    style: {color: "#595959",fontSize: '16px',fontWeight: 500,marginTop:40,cssClass: 'apexcharts-xaxis-title',}, },
                                    min: 0,},
                                    tooltip: {
                                    x: {format: 'dd MMM yyyy'}},
                                            }}
                                            series={[ {name: 'INR',data:[...this.state.valuesSeriesdata2],}
                                            ]}
                                type="bar"
                                width="100%"
                                height={350} />
                             }
                            </div>
                        </div>
                        <div className='graph-header graph-x-p'  >Time</div>
                    </div>
                    
                    </Row>                      
            </div>
           </div>
        )
    }
}
